.faq_container .faq_content {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Takes up full width within max-width constraint */
  padding: 2rem;
  box-sizing: border-box; /* Ensures padding is included in width calculation */
  
}

.main_container {
  display: flex;
  justify-content: center;
  background: white;
}

.faq_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  text-align: center;
  margin: 3rem 0;
}

.faq_header .head {
  color: #2c3e50;
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.02rem;
  text-transform: uppercase;
  margin: 0;
}

.faq_header .subhead {
  color: #7f8c8d;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.8rem;
  letter-spacing: -0.02rem;
  margin: 0;
}

.faq_content .faq_item_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
}

.faq_item_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

    width: 80%;
    max-width: 900px; 
  
  margin: 0 auto;
  margin-bottom: 4rem;
}

.faq_item_container .faq_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  justify-content: center;
  padding: 1.3rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 10px 20px rgba(16, 24, 40, 0.15), 0px 6px 6px rgba(16, 24, 40, 0.1);

  transition: all 0.3s ease;
  margin-bottom: 12px;
}

.faq_item .item_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  align-self: stretch;

}

.faq_item_container .faq_item:hover {
  background-color: white;
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.q_and_ans .question {
  color: #2c3e50;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.7rem;
}

.q_and_ans .ans {
  color: #2c3e50;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6rem;
}

.item_content .faq_icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.icon:hover {
  background-color: #f0f0f0;
}

.icon.plus {
  background-color: #28a745;
  border-color: #28a745;
}

.icon.minus {
  background-color: #e74c3c;
  border-color: #e74c3c;
}

.icon i {
  font-size: 1.5rem;
}

/* Tabs Container */
.tabs_container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 4rem;
}

/* Tabs Wrapper */
.tabs {
  display: flex;
  gap: 15px; /* Spacing between tabs */
  
}

/* Individual Tab Box */
.tab_box {
  background: white;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 140px;
  /* min-width: 140px; */
  height: auto;
  /* min-height: 140px; */
  cursor: pointer;
  flex: 0 0 auto;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.tab_title {
  font-size: 12px;
  font-weight: bold;
  color: #0052cc;
  max-width: 90%;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

/* Hover Effect */
.tab_box:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Active Tab */
.active_box {
  background: #ffbf00;
  /* color: white; */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tab_icon {
  font-size: 30px;
  margin-bottom: 5px;
  color: #0052cc;
}

.active_box .tab_icon,
.active_box .tab_title {
  color: #0052cc;
}

.tabs_container::-webkit-scrollbar {
  height: 8px;
}

.tabs_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tabs_container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.tabs_container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Responsive Adjustments */
@media only screen and (max-width: 1024px) {
  .faq_container .faq_content {
    gap: 1.5rem;
    padding: 1.5rem;
    max-width: 100%;
  }

  .faq_header .head {
    font-size: 2.5rem;
  }

  .faq_header .subhead {
    font-size: 1.125rem;
  }

  .q_and_ans .question {
    font-size: 1.125rem;
  }

  .q_and_ans .ans {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .tabs_container {
    padding: 15px;
  }

  .tab_box {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 768px) {
  .faq_container .faq_content {
    gap: 1.5rem;
    padding: 1rem;
    max-width: 100%; 
  }

  .faq_header .head {
    font-size: 2rem;
  }

  .faq_header .subhead {
    font-size: 1.125rem;
  }

  .faq_item_container {
    max-width: 100%;
  }

  .faq_item .item_content {
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  .tabs_container {
    padding: 10px;
  }
  .tab_box {
    width: 100px;
    height: 100px;
      }

}

@media only screen and (max-width: 480px) {
  .faq_header .head {
    font-size: 20px;
  }

  .faq_header .subhead {
    font-size: 1rem;
  }
  .tab_box {
    width: 100px;
    height: 100px;
      }
  .faq_container {
    width: 100%;
    padding: 1rem;
  }
  .faq_item_container{
    width: 100%;
  }
.tab_title{
  font-size: 10px;
}
  .tabs_container {
    padding: 5px;
  }
  .faq_item .item_content{
    flex-direction: row;
    justify-content: center;
  }
  .q_and_ans .question{
    font-size: 16px;
  }
  .q_and_ans .ans{
    font-size: 14px;
  }
}
