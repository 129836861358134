
.blogDetail {
	width: 100%;
	max-width: 1400px;
	margin: auto;
	padding: 0 50px 40px ;
	background: #ffffff;
	border-radius: 12px;
	box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.08);
}

.blogHeader {
	text-align: center;
}

.blogImage {
	width:55%;
	max-height: 400px;
	object-fit: cover;
	border-radius: 14px;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

.author {
	font-size: 16px;
	color: #666;
	font-family: "Roboto",sans-serif;
	margin-top: 15px;
	text-align: center;
}

.title {
	font-size: 30px;
	font-family: "Roboto",sans-serif;
	font-weight: bold;
	margin-top: 20px;
	color: #222;
	text-align: center;
}

.subheading {
	font-size: 18px;
	color: #444;
	font-family: "Roboto",sans-serif;
	text-align: center;
	margin-top: 10px;
}

.blogContainer {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	margin-top: 40px;
}

.blogContent {
	flex: 3;
	display: flex;
	gap: 20px;
}

.rightSidebar {
	flex: 1;
	min-width: 270px;
	/* margin-left: -20px; */
}

.description {
	flex: 3;
	width: 700px;
	padding: 35px;
	background: #ffffff;
	border-radius: 14px;
	box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1); 
	line-height: 1.8;
	font-size: 16px;
	color: #333;
	transition: box-shadow 0.3s ease-in-out;
}

.description:hover {
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
}

.sectionHeading {
	margin-top: 35px;
	font-size: 22px;
	font-family: "Roboto"; 
	font-weight: bold;
	color: #222;
	border-bottom: 4px solid #0052cc;
	padding-bottom: 10px;
	width: max-content;
}

.breadcrumb {
	display: inline-block;
	padding: 14px 20px;
	border: none;
	border-radius: 8px;
	cursor: pointer;
	font-size: 18px;
	font-weight: bold;
	color: black;
	transition: background 0.3s ease-in-out;
}

.home {
	color: #0052cc;
	text-decoration: none;
	font-weight: bold;
}

.home:hover {
	text-decoration: underline;
}

.toc {
	position: sticky;
	top: 80px;
	background: #f8f9fa;
	padding: 20px;
	border-radius: 12px;
	height: fit-content;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
	max-width: 280px; 
	width: 100%; 
	min-width: 280px; 
	transition: all 0.3s ease-in-out;
	margin-left: -20px;
}

.toc ul {
	list-style: none;
	padding-left: 0;
	margin-top: 12px;
}

.tocItem {
	list-style: none;
	cursor: pointer;
	padding: 10px 20px;
	display: flex;
	align-items: center;
	color: #007bff;
	font-size: 16px;
	font-weight: 500;
	font-family: "Roboto",sans-serif ; 
	transition: all 0.3s ease-in-out;
	border-left: 5px solid transparent;
	border-radius: 8px;
	margin-bottom: 5px;
}

.tocItem:hover {
	text-decoration: none;
	color: #0056b3;
	background: rgba(0, 123, 255, 0.12);
	border-left: 5px solid #007bff;
}

.tocItem.active {
	font-weight: bold;
	color: #0052cc;
	border-left: 5px solid #0052cc;
	background: rgba(0, 123, 255, 0.2);
}
.toc h4 {
	font-size: 20px;
	font-family: "Roboto",sans-serif; 
	font-weight: bold;
}
/* Important Posts */
.important_post {
	margin-top: 20px;
	width: 100%;
	padding: 20px;
	background: rgba(255, 255, 255, 0.8);
	border-radius: 12px;
	backdrop-filter: blur(10px);
	box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
	max-width: 340px;
	padding-bottom: 10px;
	border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .important_post_heading {
	font-size: 18px;
	color: #333;
	font-weight: 600;
	margin-bottom: 15px;
  }
  
  .post_list {
	list-style: none;
	padding: 0;
	margin: 0;
  }
  
  .post_item {
	font-size: 14px;
	color: #1f2937;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	padding: 8px;
	border-radius: 6px;
	transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .post_item:hover {
	background: rgba(31, 41, 55, 0.1); /* Light grayish hover effect */
	transform: scale(1.02);
  }
  
  .post_image {
	width: 50px;
	height: 50px;
	margin-right: 12px;
	border-radius: 5px;
  }
  
@media screen and (max-width: 992px) {
	.blogContainer {
		flex-direction: column;
		gap: 30px;
	}

	.blogContent {
		flex-direction: column;
		gap: 20px;
		padding: 0;
	}

	.rightSidebar {
		min-width: 100%;
	}

	.blogImage {
		width: 100%;
		max-height: 380px;
	}

	.title {
		font-size: 28px;
		margin-top: 15px;
	}

	.subheading {
		font-size: 18px;
		margin-top: 8px;
	}

	.description {
		padding: 20px;
		font-size: 16px;
	}

	.sectionHeading {
		font-size: 22px;
	}

	.toc {
		max-width: 100%;
		position: relative;
		margin-left: 0;
		padding: 15px;
	}

	.toc h4 {
		font-size: 18px;
	}

	.toc ul {
		display: none;
	}

	.mobileToc.expanded ul {
		display: block;
	}

	.breadcrumb {
		font-size: 16px;
		padding: 10px 15px;
	}
}

@media screen and (max-width: 768px) {
	.blogDetail {
		width: 90%;
		max-width: 100%;
		padding: 0 !important;
		margin: 0 auto !important;
		box-shadow: none;
		border-radius: 0;
	  }
	.blogDetail .toc{
		margin-top: -90px;
		width:80%;
margin-left: 35px;
	}
	.blogHeader {
		font-size: 28px;
	}

	.title {
		font-size: 24px;
		margin-top: 12px;
	}

	.subheading {
		font-size: 16px;
	}

	.blogImage {
		max-height: 300px;
		border-radius: 8px;
	}

	 .description {
    margin-top: 50px;
    width: 100%; 
    max-width: 100%;  
    overflow-wrap: break-word;  
    word-wrap: break-word;
    white-space: normal;
    padding: 15px;
    background: none !important; 
    font-size: 15px;
    line-height: 1.6;
    box-shadow: none !important;  
  }

	.sectionHeading {
		font-size: 20px;
		width: 100%; 
		max-width: 100%;  
		overflow-wrap: break-word;  
		word-wrap: break-word;
		white-space: normal;
		padding: 15px;  
		line-height: 1.6;
		box-shadow: none;


	}

	.breadcrumb {
		font-size: 14px;
		padding: 8px 12px;
	}

	.toc {
		display: block; 
		position: relative;
		background: #f8f9fa;
		align-items: center;
		border-radius: 8px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	  }
	
	  .toc ul {
		display: block;
		margin-top: 10px;
	  }
	
	  .toc.expanded ul {
		display: block !important;
	  }
	
	  .toc h4 {
		cursor: pointer;
		font-size: 18px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	  }
}

@media screen and (max-width: 480px) {
	.blogHeader {
		font-size: 26px;
	}

	.title {
		font-size: 22px;
	}

	.subheading {
		font-size: 15px;
	}

	.blogImage {
		max-height: 250px;
	}

	.description {
		width: 100%;
		padding: 12px;
		font-size: 14px;
	}

	.sectionHeading {
		font-size: 18px;
	}
	.rightSidebar{
		margin-left: -5px;
		margin-bottom: 10px;
	}
	.important_post{
		margin-left: 10px;
		margin-bottom: 10px;
	}

	
}




