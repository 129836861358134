.join_rakeback_container_wrapper {
	display: flex;
	justify-content: center;
	padding-block: 4rem;
	margin-top: -100px;
}
.join_rakeback_container {
	width: 100%;
	display: flex;
	justify-content: center;
}
.join_rakeback_container .join_wrapper {
	position: relative;
	width: 100%;
	display: flex;
	padding: 5rem 0.625rem;
	flex-direction: column;
	justify-content: center;
	gap: 0.625rem;
	border-radius: 1rem;
	background: #f5fbff;
	box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
		0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.join_wrapper .join_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
}
/* absolutes */
.join_wrapper .icon1 {
	position: absolute;
	left: 10rem;
	top: 0;
	transform: translateY(-50%);
	width: 4.74513rem;
	height: 4.9375rem;
	flex-shrink: 0;
}
.join_wrapper .icon2 {
	position: absolute;
	left: 0;
	bottom: 3rem;
	width: 6.40019rem;
	height: 7.625rem;
	flex-shrink: 0;
}
.join_wrapper .icon3 {
	position: absolute;
	top: 2rem;
	right: 0;
	width: 5.56625rem;
	height: 6.26419rem;
	flex-shrink: 0;
}
.join_wrapper .icon4 {
	position: absolute;
	right: 3rem;
	bottom: 2.5rem;
	width: 14.14775rem;
	height: 9.375rem;
	flex-shrink: 0;
}
@media only screen and (max-width: 768px) {
	.join_rakeback_container_wrapper {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
	.join_rakeback_container .join_wrapper {
		padding: 4rem 0.625rem;
	}

	/* absolutes */
	.join_wrapper .icon1 {
		width: 3.36363rem;
		height: 3.5rem;
	}
	.icon1 svg {
		width: 3.36363rem;
		height: 3.5rem;
		flex-shrink: 0;
	}
	.join_wrapper .icon2 {
		bottom: 0;
		width: 4.3125rem;
		height: 5.125rem;
	}
	.icon2 svg {
		width: 4.3125rem;
		height: 5.125rem;
		flex-shrink: 0;
	}
	.join_wrapper .icon3 {
		display: none;
	}
	.join_wrapper .icon4 {
		display: none;
	}
}
@media only screen and (max-width: 768px) {
	.join_rakeback_container_wrapper {
	margin-top: -70px;
	}
}
