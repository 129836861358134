.featured_container {
	margin-top: 50px;
}

.featured_content {
	padding: 20px;
}

.header {
	text-align: center;
	margin-bottom: 30px;
}

.head {
	color: #2c3e50;
	font-family: Futura;
	font-size: 3rem;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: -0.02rem;
	color: black;
	margin: 0;
}

.type {
	font-size: 13px; /* Adjusted font size */
	margin-top: 2px;
	font-family: "Roboto", sans-serif;
}

.subhead {
	font-size: 16px;
	color: gray;
	font-family: "Roboto", sans-serif;
}

/* Wrapper for the Carousel to allow positioning of controls */
.carouselWrapper {
	position: relative;
	margin-bottom: 20px;
}

.carouselRow {
	display: flex;
	justify-content: space-between;
}

.carousel-item {
	display: flex;
	justify-content: center;
}

.card {
	width: 30%; /* Each card takes up about 1/3 of the carousel width */
	margin: 10px;
	 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: auto; /* Set a fixed height for all cards */
	overflow: hidden; /* Ensure content doesn’t overflow the card */
}

.cardImage {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	cursor: pointer;
}

.cardContent {
	padding: 15px;
	display: flex;
	flex-direction: column;
	flex-grow: 1; 
	justify-content: space-between; 
	cursor: pointer;
}

.cardContent h5 {
	margin-bottom: 10px;
}

.cardMeta {
	font-size: 14px;
	color: gray;
	display: flex;
	font-family: "Roboto", sans-serif;
	justify-content: flex-start; 
	gap: 10px; 
	margin-top: auto; 
}

.writer,
.date {
	font-size: 0.875rem;
	color: var(--gray-900, #101828);
	font-weight: 600;
	font-family: "Roboto", sans-serif;
	cursor: pointer;
}

.date {
	color: var(--gray-600, #475467);
	font-weight: 400;
	font-family: "Roboto", sans-serif;
}

.type {
	font-size: 13px;
	color: var(--gray-900, #101828); /* Ensures type color consistency */
	font-weight: 600;
	font-family: "Roboto", sans-serif;
}

/* Style for the previous/next carousel arrows, inside the carouselWrapper */
.carousel-control-prev,
.carousel-control-next {
	position: absolute;
	top: 50%;
	z-index: 1;
	transform: translate(0, -50%);
	width: 5%;
	color: white; /* Change arrow color */
	background: rgba(0, 0, 0, 0.3); /* Optional: add a transparent background for better visibility */
	border-radius: 50%; /* Optional: give arrows a rounded background */
	left: -50px;
}

/* Adjust the position of the prev/next arrows to the sides of the carousel */
.carousel-control-prev {
	left: 10px;
}

.carousel-control-next {
	right: 0px;
}

/* Optional: Style the carousel indicators (dots) */
.carousel-indicators {
	bottom: -30px;
}

.carousel-indicators li {
	background-color: white; /* Change indicator color */
}

.heading_card {
	font-size: 20px;
	font-weight: 600;
	color: var(--gray-900, #101828);
	font-family: "Roboto", sans-serif;
}

.para {
	color: #3e3e59;
	font-size:16px;
	font-family: "Roboto", sans-serif;
}

.writer {
	color: var(--gray-900, #101828);
	font-size: 0.875rem;
	font-weight: 600;
}

.date {
	color: var(--gray-600, #475467);
	font-size: 0.875rem;
	font-weight: 400;
}

.type {
	font-size: 13px;
}
@media (max-width: 768px) {
	.featured_container {
		margin-top: 30px;
		padding: 0 10px;
		display: flex;
		justify-content: center; 
		align-items: center; 
	}

	.carouselRow {
		display: flex;
		flex-direction: row;
		overflow-x: auto;
		scroll-snap-type: x mandatory;
		gap: 10px;
		scroll-behavior: smooth;
		justify-content: center; 
	}

	.card {
		width: 80%;
		flex: 0 0 80%;
		scroll-snap-align: start;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: white;
		/* border-radius: 12px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		padding: 15px; */
		margin-bottom: 20px;
	}

	.cardImage {
		width: 100%;
		height: 180px;
		object-fit: cover;
		border-radius: 10px;
	}

	.carousel-control-prev,
	.carousel-control-next {
		width: 12%;
		height: 40px;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}

	.carousel-control-prev {
		left: 5px;
	}

	.carousel-control-next {
		right: 5px;
	}

	/* Hide scrollbar for a cleaner look */
	.carouselRow::-webkit-scrollbar {
		display: none;
	}
}
@media (max-width: 480px) {
	.head{
		font-size: 1.75rem;
	}
	.heading_card{
		font-size: 18px;
	}
.para{
display: none;
}
}
 