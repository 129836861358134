.CardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;
}

.PokerCard {
  width: 100%;
  max-width: 400px;
  margin-bottom: -1.3rem;
  border: 0.0625rem #eaecf0 solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 0.75rem;
  background: var(--base-white, #fff);
  box-shadow: 0px 10px 20px rgba(16, 24, 40, 0.15), 0px 6px 6px rgba(16, 24, 40, 0.1);

}

.CardContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 1.5rem;
  box-sizing: border-box;
}

.HeaderAndId, .FooterContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.HeaderAndIdContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75rem;
}

.Image152 {
  width: 50%;
  height: auto;
}

.HeaderId {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Head {
  color: #101828;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;;
  font-weight: 600;
  line-height: 1.5rem;
  word-wrap: break-word;
}

.Id {
  color: #344054;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;;
  font-weight: 400;
  line-height: 1.25rem;
  word-wrap: break-word;
}

.CardFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.Divider {
  align-self: stretch;
  height: 0.0625rem;
  background: #eaecf0;
}

.FooterContent {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1.5rem 1rem;
  gap: 0.5rem;
}

.PokerStatus {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.625rem;
}

.Badge {
  padding: 0.125rem 0.625rem;
  mix-blend-mode: multiply;
  background: #fef3f2;
  border-radius: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Text {
  text-align: center;
  color: #b42318;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;;
  font-weight: 500;
  line-height: 1.25rem;
  word-wrap: break-word;
}

.PokerDate {
  color: #475467;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;;
  font-weight: 400;
  line-height: 1.5rem;
  word-wrap: break-word;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .PokerCard {
    width: 100%;
    padding: 1rem;
  }
  .CardContent {
    padding: 1rem 0.75rem;
  }
  .FooterContent {
    padding: 0 0.75rem 1rem;
  }
  .Image152 {
    width: 100%;
    height: auto;
  }
  .Text {
    font-size: 0.75rem;
  }
  .PokerDate {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 480px) {
  .PokerCard {
    padding: 0.75rem;
  margin-bottom: 0px;

  }
  .Text {
    font-size: 0.75rem;
  }
  .PokerDate {
    font-size: 0.75rem;
  }
}
