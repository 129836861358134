.KycStatusContainer {
  display: flex;
  /* padding: 0rem 2rem; */
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
}
.StatusContent {
  display: flex;
  padding: 0.9375rem 2.5rem 0.9375rem 0.9375rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.5rem;
}
.illustration3_design {
  width: 1070px;
  height: 240px;
  background: #dde3ef;
  border-radius: 0.5rem;
}
.kyc_status_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-position: calc(100% - 150px) center;
  background-repeat: no-repeat; 
  background-size: 200px;
  width: 100%;
  height: 200px;
  border-radius: 12px;
  padding: 20px 30px;
  position: relative;
  color: white;
  overflow: hidden;
  background-color: #0052cc;
}
/* .heading_container{
  margin-left: 5rem;
} */
.heading{
  font-size: 30px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;;
}
.sub_heading{
  font-size: 20px;
  font-family: "Roboto", sans-serif;;
  font-weight: bold;
}
.successBorder {
  border-top: 1px solid var(--Success, #11c15b);
  border-right: 1px solid var(--Success, #11c15b);
  border-bottom: 1px solid var(--Success, #11c15b);
  border-left: 5px solid var(--Success, #11c15b);
}
.failedBorder {
  border-top: 1px solid #ff5252;
  border-right: 1px solid #ff5252;
  border-bottom: 1px solid #ff5252;
  border-left: 5px solid #ff5252;
}
.StatusHead {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
.StatusIcon svg {
  width: 1rem;
  height: 1rem;
}

.HeadText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
}
.HeadLabel {
  color: #344054;
  font-family: "Roboto", sans-serif;;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
}
.HeadBadge {
  display: flex;
  padding: 0.125rem 0.625rem;
  align-items: center;
  border-radius: 1rem;
  background: var(--success-50, #ecfdf3);
  mix-blend-mode: multiply;
}
.HeadBadge .Text {
  text-align: center;
  font-family: "Roboto", sans-serif;;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}
.successBadgeText {
  color: var(--success-700, #027a48);
}
.failedBadgeText {
  color: var(--success-700, #b42318);
}
.StatusMessage {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
}
.MessageText {
  color: var(--text-secondary, #475467);
  /* Text xs/Regular */
  font-family: "Roboto", sans-serif;;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 150% */
  display: flex;
  justify-content: start;
  align-items: center;
}
.retry {
  padding-left: 0.6rem;
  color: #0052cc;
  font-family: "Roboto", sans-serif;;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
}
@media only screen and (max-width: 480px) {
  .kyc_status_header {
    background-position-x: 190px;
    background-size: 160px;
  }
  .heading{
    font-size: 16px;
  }
  .sub_heading{
      font-size: 13px;
  }
}