/* Navbar Container */
.navbar_container {
    padding-block: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative; /* Makes the navbar sticky */
    top: 0; /* Ensures the navbar sticks to the top of the viewport */
    z-index: 1000; /* Keeps the navbar above other content */
    background-color: white; /* Ensures the background color is visible when scrolling */
    background: #0052cc;
    box-shadow: 0px 1px 0px white; /* Optional shadow for visibility */

}

/* Navbar Links Container */
.navbar_container .navbar_link_container {
    display: flex;
    align-items: center;
    gap: 5.625rem;
    margin-left: 200px;
    
}

/* Menu Container */
.navbar_link_container .menu_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

/* Button Link Container */
.navbar_link_container .btn_link_container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-family: "Roboto", sans-serif;;

}

/* Burger Menu for Mobile */
.navbar_container .burger_menu {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    background: var(--base-white, #fff);
    display: none;
    cursor: pointer;
    border: 0;
}

.burger_menu .icon {
    width: 1.5rem;
    height: 1.5rem;
}
.menu_container .secondary_link{
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    font-family:"Roboto", sans-serif;
}
/* Mobile Links Container */
.link_mobile_container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #0052cc;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    /* border-radius: 0.5rem; */
    box-shadow: 0px 0px 10px 0px #e2e2e2;
    z-index: 1000;
}

.link_mobile_container .menu_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}
.signin_button{
    background:#ffbf00;
    padding: 10px 16px;
    font-weight: bold;
    font-size: 14px;
    color: black;
    border-radius: 30px;
    font-family:"Roboto", sans-serif;
}
.signin_button:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: white;
    transform: scale(1.05);
    
}
.link_mobile_container .btn_link_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Mobile Header */
.link_mobile_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
}

.link_mobile_header button {
    background: none;
    border: none;
    cursor: pointer;
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
    .navbar_container {
        padding-block: 0.75rem;
    }
    .navbar_container .navbar_link_container {
        display: none;   
    }

    .navbar_container .burger_menu {
        display: flex;
        margin-left: 400px;
    }
    .logo_container_pc{
margin-left: -10px;
    }
    .menu_container{
        align-items: flex-start;
    }
    
  .signin_button{
 width: 140px;
    }
  
}
@media only screen and (max-width: 480px){
    .logo_container_pc{
        margin-left: 5px;
    }
    .navbar_container .burger_menu {
        display: flex;
        margin-left: 170px;
    }
}

