.WithdrawContent,
.WithdrawImageWrapper,
.BalanceAndWinnings,
.BalanceContent {
	width: 100%;
	display: flex;
	align-self: stretch;
	align-items: flex-start;
	gap: 1.5rem;
	/* height:14rem; */
	
}
.WithdrawContent {
	justify-content: flex-start;
}
.WithdrawImageWrapper {
	justify-content: flex-end;
}
.WithdrawImageWrapper img {
	width: 100%;
	max-height: 400px;
}
.BalanceAndWinnings {
	flex-direction: row;
justify-content: space-around;
	
}
.Income {
	display: flex;
	flex-direction: column;
	/* justify-content: flex-start;
	align-items: flex-start; */
	align-self: stretch;
	background: rgb(30, 136, 229);
	;
	box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
	border-radius: 0.75rem;
	border: 0.0625rem solid #eaecf0;
}
.Balance {
	display: flex;
	flex-direction: column;
	/* justify-content: flex-start;
	align-items: flex-start; */
	align-self: stretch;
	background: purple;
	box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
	border-radius: 0.75rem;
	border: 0.0625rem solid #eaecf0;
}
.Card {
	display: flex;
	flex-direction: column;
	/* justify-content: flex-start;
	align-items: flex-start; */
	align-self: stretch;
	background: black;
	box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
	border-radius: 0.75rem;
	border: 0.0625rem solid #eaecf0;
}

.BalanceContent {
	padding: 1.25rem;
	flex-direction: column;
}

.BalanceContent .Icon {
	display: flex;
	width: 3.125rem;
	height: 3.125rem;
	padding: 0.78125rem;
	justify-content: center;
	align-items: center;
	background-color: white;
	border-radius: 50%;
}

.BalanceContent .Icon svg {
	width: 1.5625rem;
	height: 1.5625rem;
	flex-shrink: 0;
}

.BalanceText {
	display: flex;
	/* flex-direction: column; */
	gap: 2.625rem;
}
.BalanceText_card{
	display: flex;
	gap: 4.625rem;
}
.BalanceHead {
	align-self: stretch;
	color: white;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.25rem;
	word-wrap: break-word;
}
.Income_head{
	align-self: stretch;
	color: white;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.25rem;
	word-wrap: break-word;
}
.Number {
	color: white;
	font-size: 1.875rem;
	font-weight: 600;
	line-height: 2.375rem;
	word-wrap: break-word;
}
.Income_number{
	color: white;
	font-size: 1.875rem;
	font-weight: 600;
	line-height: 2.375rem;
	word-wrap: break-word;
}
@media only screen and (max-width: 1024px) {
	.HomeWithdraw {
		width: 100%;
	}

	.WithdrawContent {
		flex-direction: column;
	}

	.WithdrawImageWrapper img {
		width: 100%;
	}
}
