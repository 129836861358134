.AddressDetailsContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.25rem;
	align-self: stretch;
}
.AddressDetailsContainer .Text {
	color: var(--gray-900, var(--text-primary, #101828));
	font-family: Inter;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.75rem; /* 155.556% */
	display: flex;
	align-items: center;
	gap: 0.625rem;
}
.AddressDetailsContainer .Text .edit {
	display: flex;
	align-items: center;
	gap: 0.25rem;
}
.ActionDetailsContainer .Text .edit svg {
	width: 0.875rem;
	height: 0.875rem;
}
.ActionDetailsContainer .Text .edit span {
	color: #52131r  !important;
	font-family: Inter;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.125rem; /* 128.571% */
	text-decoration-line: underline;
}
.AddressDetailsForm {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 0.75rem;
	border: 1px solid var(--gray-200, #eaecf0);
	background: var(--base-white, #fff);

	/* Shadow/sm */
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
		0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}
.AddressDetailsContent {
	display: flex;
	padding: 1.5rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
	background: var(--base-white, #fff);
}
.InputRow {
	display: flex;
	/* height: 4.375rem; */
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
}
.UploadArea {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.375rem;
	align-self: stretch;
}
.UploadLabel {
	color: var(--gray-700, #344054);

	/* Text sm/Medium */
	font-family: Inter;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem; /* 142.857% */
}
.FileUpload {
	display: flex;
	/* height: 7.875rem; */
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
}
.FileUploadBase {
	display: flex;
	padding: 1rem 1.5rem;
	flex-direction: column;
	align-items: center;
	gap: 0.25rem;
	align-self: stretch;
	border-radius: 0.75rem;
	border: 1px solid var(--gray-200, #eaecf0);
	background: var(--base-white, #fff);
}
.FileUploadContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.75rem;
	align-self: stretch;
}
.FileUploadContent .Icon {
	display: flex;
	width: 2.5rem;
	height: 2.5rem;
	padding: 0.625rem;
	justify-content: center;
	align-items: center;
	border-radius: 1.75rem;
	border: 6px solid var(--gray-50, #f9fafb);
	background: var(--gray-100, #f2f4f7);
}
.FileUploadContent svg {
	width: 1.25rem;
	height: 1.25rem;
	flex-shrink: 0;
}
.UploadTextContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.25rem;
	align-self: stretch;
}
.UploadTextContainer .Action {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 0.25rem;
	align-self: stretch;
}
/* .Navbtn {
}
.UploadTextContainer .Text {
}
.ActionText {
} */
 
.ActionSubtext {
	color: var(--gray-600, var(--text-secondary, #475467));
	text-align: center;

	/* Text xs/Regular */
	font-family: Inter;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.125rem; /* 150% */
}
.btn_container{
	font-size: 1rem;
}
/* Optional: Customizing dropdown appearance */
select.form-select {
	max-width: 400px;
	margin-top: 10px;
  }
  
.SelectInput {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.375rem;
	flex: 1 0 0;
}
.SelectInputContent {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.375rem;
	align-self: stretch;
}
.InputLabel {
	color: var(--gray-700, #344054);

	/* Text sm/Medium */
	font-family: Inter;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem; /* 142.857% */
}
.InputWrapper {
	display: flex;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 0.5rem;
	border: 1px solid var(--gray-300, #d0d5dd);
	background: var(--base-white, #fff);

	/* Shadow/xs */
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.SelectTextWrapper {
	display: flex;
	padding: 0.625rem 0rem 0.625rem 0.875rem;
	align-items: center;
	border-radius: 0.5rem;
	border: 0.0625rem solid #eaecf0;
	gap: 0.5rem;
	flex: 1 0 0;
}
.SelectText {
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
	flex: 1 0 0;
}
.SelectInput .Text {
	color: var(--gray-900, var(--text-primary, #101828));

	/* Text md/Regular */
	font-family: Be Vietnam;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem; /* 150% */
}
.DropdownIcon {
	display: flex;
	padding: 0.625rem 0.875rem;
	justify-content: space-between;
	align-items: center;
}
.DropdownIcon svg {
	width: 1.25rem;
	height: 1.25rem;
}

/*  */
/* Topmost Parent Selector for TextInput */
.TextInput {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.375rem;
	flex: 1;
}

/* Child Selector for TextInputWithLabel */
.TextInput .TextInputWithLabel {
	align-self: stretch;
	/* height: 20px; */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.375rem;
}

/* Child Selector for TextLabel */
.TextInput .TextInputWithLabel .TextLabel {
	color: #344054;
	font-size: 14px;
	font-family: "Inter";
	font-weight: 500;
	line-height: 20px;
	word-wrap: break-word;
}
.TextInput .TextFieldInput {
	display: flex;
	padding: 0.625rem 0.875rem;
	align-items: center;
	gap: 0.5rem;
	align-self: stretch;
	border-radius: 0.5rem;
	border: 1px solid var(--gray-300, #d0d5dd);
	background: var(--base-white, #fff);

	/* Shadow/xs */
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.TextFieldInput,
.TextFieldInput::placeholder {
	color: var(--gray-900, var(--text-primary, #101828));

	/* Text md/Regular */
	font-family: Be Vietnam;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem; /* 150% */
}
.TextFieldInput::placeholder {
	opacity: 0.4;
}
.FormFooter {
	align-self: stretch;
	/* height: 4.5625rem; */
	padding-bottom: 1rem;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;
	display: flex;
}

.Divider {
	align-self: stretch;
	height: 0.0625rem;
	background: #eaecf0;
}

.Content {
	align-self: stretch;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	justify-content: flex-end;
	align-items: center;
	gap: 1rem;
	display: inline-flex;
}

.Actions {
	/* flex: 1 1 0; */
	/* height: 2.5rem; */
	justify-content: flex-end;
	align-items: center;
	gap: 0.75rem;
	display: flex;
}
.SelectMenu {
	position: absolute;
	top: 3.5rem;
	left: 0;
	right: 0;
	z-index: 1;
	background: white;
	box-shadow: 0px 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
	border-radius: 0.75rem;
	border: 0.0625rem solid #eaecf0;
	display: flex;
	flex-direction: column;
	max-height: 200px;
	overflow-y: auto;
}
.SelectMenu > button {
	padding: 0.7rem 1rem;
	color: #101828;
	display: flex;
	align-items: center;
	gap: 0.4rem;

	font-family: "Be Vietnam";
	font-size: 16px;
	font-weight: 500;
	line-height: 24px; /* 150% */
	background-color: white;
	border: 0;
}
@media only screen and (max-width: 768px) {
	.InputRow {
		flex-direction: column;
		height: auto;
	}
	.TextInput .TextInputWithLabel {
		height: auto;
	}
}
