.main_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: white;
  color: #28282c;
  font-size: 18px;
  padding: 10px 20px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.Button:hover {
  background-color: #212121;
  transform: scale(1.05);
  color: white;
}

.Button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.6);
  /* Fixed invalid color format */
}

.Button:active {
  transform: scale(0.98);
  background-color: #212121;
  color: white;
}

.withdrawal_button {
  margin-top: 10px;
  background: #003fb8;
  color: #ffbf00;
  padding: 10px 30px;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  ;
  font-weight: bold;
}

.filter_container {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter_heading {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  ;
  font-weight: 600;
  color: #28282c;
}

.filters {
  display: flex;
  justify-content: space-between;
  color: #28282c;
  font-size: 14px;
  gap: 14px;
  font-family: "Roboto", sans-serif;
  ;
}

.chart_heading {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  color: black;
  font-family: "Roboto", sans-serif;
  ;
}

.explore {
  margin-top: 20px;

}

.explore button {
  text-decoration: none;
  background: #ffbf00;
  border: 2px solid #ffbf00;
  color: black;
  padding: 5px 10px;
  text-align: center;
  border-radius: 30px;
  font-family: "Roboto", sans-serif;
  ;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.dashboard_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-position: calc(100% - 100px) center;
  background-repeat: no-repeat;
  background-size: 175px;
  width: 100%;
  border-radius: 12px;
  padding: 20px 30px;
  position: relative;
  color: white;
  overflow: hidden;
  background-color: #0052cc;
}

.chart_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
}

.dashboard_greeting {
  font-size: 14px;
  opacity: 0.8;
  font-family: "Roboto", sans-serif;
  ;
}

.dashboard_username {
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  ;
  margin-top: 5px;
}

.dashboard_explore {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  ;
  font-weight: bold;
  margin-top: 5px;
}

.dashboard_image_wrapper {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.dashboard_image {
  height: 310px;
  width: auto;
  object-fit: cover;
  margin-top: -100px;
  margin-left: 66px;
}

.events_heading {
  font-size: 20px;
  font-weight: bold;
}

.events_container {
  width: 100%;
  max-width: 300px;
  border-radius: 16px;
  height: 550px;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.wallet_balance {
  font-size: 24px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  ;
  margin: 5px 0;
  margin-top: 10px;
}

.wallet_balance_head {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  ;
  margin-top: 10px;
}

.withdrawal_container_inner {
  margin-top: 20px;

}

.withdraw_icon {
  width: 38px;
  height: 38px;
  stroke: white;
}

.withdrawal_container {
  width: 100%;
  max-width: 300px;
  border-radius: 16px;
  background-color: #0052cc;
  height: 240px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

}

@media (max-width: 768px) {
  .dashboard_card {
    background-size: 40%;
    background-position-x: 220px;
    padding: 20px;
  }

  .dashboard_username {
    font-size: 16px;
  }

  .dashboard_explore {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .main_container {
    justify-items: center;
  }

  .Button {
    font-size: 14px;
    padding: 6px 12px;
  }
  .filter_heading {
    margin-top: 60px;

    text-align: center;
    font-size: 16px;
    width: 360px;
  }

  .filters {
    font-size: 13px;
    width: 360px;
  }

  .dashboard_card {
    background-size: 40%;
    background-position-x: 170px;
    padding: 10px;
    width: 360px;
    height: 140px;
  }

  .dashboard_username {
    font-size: 12px;
  }

  .dashboard_greeting {
    font-size: 12px;
  }

  .withdrawal_container {
    margin-left: 30px;
    margin-top: -310px;
    height: 190px !important;
  }

  .dashboard_explore {
    font-size: 14px;
  }

  .events_heading {
    font-size: 16px;
  }

  .explore {
    margin-top: 15px;
  }

  .explore button {
    padding: 4px 10px;
    font-size: 13px;
  }

  .chart_container {
    height: 300px;
    width: 360px;

  }

  .events_container {
    display: none;
  }

  .chart_heading {
    margin-top: 340px;
    font-size: 16px;
    width: 360px;
    text-align: center;
  }

  .dashboard_image {
    margin-left: 18px;
  }

  .wallet_balance_head {
    font-size: 14px;
    margin: 0px;
  }

  .withdraw_icon {
    width: 32px;
    height: 32px;
  }

  .wallet_balance {
    font-size: 22px;
    margin-bottom: -5px;
  }

  .withdrawal_button {
    font-size: 14px !important;
  }

  .dashboard_card {
    background-position-x: 185px;
  }
}