.cardContainer {
  position: relative;
  width: 80%;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: -30px auto 60px auto;
  border: 1px solid #ffbf00;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.cardContainer:hover {
  transform: scale(1.03); 
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.25);
  border: 1px solid #ffa500; 
}
.premium_star {
  color: #ffbf00; 
  font-size: 1.2em;
margin-left: 2px;
  /* text-shadow: 1px 1px 4px rgba(255, 191, 0, 0.8); */
}

/* Logo */
.logoContainer {
  flex: 0 0 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer img {
  width: 110px;
  height: 110px;
  object-fit: contain;
}

/* Content Section */
.contentSection {
  flex: 1;
  padding: 0 20px;
}
.offer_bonus_rack{
  display: flex;
  /* justify-content: right; */
  gap: 20px;
}
.offer_bonus{
color: #0052cc;
font-weight: bold;
border: 2px solid black;
border-radius: 12px;
padding: 4px 10px;
font-size: 12px;
}
.offer_rack{
  font-size: 12px;
color: #ffbf00;
  font-weight: bold;
  border:2px solid black;
  border-radius: 12px;
  padding: 4px 10px;
}
.offerBadge {
  background: linear-gradient(135deg, #ffb400, #ff6a00);
  color: #fff;
  font-weight: bold;
  padding: 8px 14px;
  border-radius: 6px;
  display: inline-block;
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.productTitle {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
  font-family: "Roboto", sans-serif;
  color: #222;
}

.availability {
  font-size: 13px;
  color: #27ae60;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
}

.description {
  font-size: 18px;
  color: #444;
  margin-bottom: 15px;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}

/* Rating Section */
.rating_container {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #ffbf00;
}
.rating_text{
  font-family: "Roboto", sans-serif;
}
/* Button Section */
.buttonSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Buttons */
.buttonSection button {
  width: 150px;
  padding: 12px 18px;
  border: none;
  border-radius: 10px;
  font-size: 17px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* Primary Button */
.buttonSection button:first-child {
  background: #ffbf00;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.3);
}

.buttonSection button:first-child:hover {
  background:#ffbf00;
  box-shadow: 0px 6px 15px rgba(0, 123, 255, 0.4);
}

/* Secondary Button */
.buttonSection button:last-child {
  background: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.buttonSection button:last-child:hover {
  background: #e2e6ea;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.numberTag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffcc00;
  color: #000;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


@media (max-width: 768px) {
  .cardContainer {
    padding: 10px;
  
  }

  .logoContainer img {
    width: 75px;
    margin-top: -130px;
    margin-left: -50px;
  }


  .productTitle {
  margin-left: -50px;
  }

  .availability {
    margin-left: -130px;
    margin-top: 38px;
  }

  .description {
margin-left: -130px;
font-size: 16px;
  }
  .numberTag {
  margin-left: 520px;
  }
}

@media (max-width: 480px) {
  .cardContainer {
    width: 100%;
    padding: -5px;
    height: 200px;
  }
  .logoContainer img {
    margin-left: -65px;
  }
  .availability {
    margin-top: 20px;
  }
  .productTitle{
    /* margin-top: 30px; */
    margin-left: -75px;
    font-size: 16px;
  }
  .logoContainer img {
    width: 50px;
    height: 50px;
  }
  .description {
    font-size: 14px; /* Adjust as needed */
    color: #444;
    line-height: 1.5em; /* Proper spacing between lines */
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin-bottom: 15px;
    font-family: "Roboto", sans-serif;
  }
  

  .numberTag {
    margin-left: 300px;
    }
  .buttonSection button {
    width: 80px;
    font-size: 13px;
    padding: 8px;
  }
  .buttonSection {
    margin-top: 0px;
      }
}



