.offer_and_deals_wrapper {
	display: flex;
	flex-direction: column;
	gap: 4rem;
	position: relative;
	/* padding-block: 7rem; */
	/* overflow: hidden; */

}

.offer_and_deals_wrapper_main {
	width: 100%;
	position: relative;
}
