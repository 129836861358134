/* Container Heading */
.container_heading {
  display: flex;
  justify-content: center; /* Center the content */
  text-align: center;
  margin: 0;
  width: 80%;
  font-size: 30px;
  font-weight: 400;
  color: #28282c;
  font-family: "ProximaNova-Medium", sans-serif;
}

.container_heading2 {
  display: flex;
  justify-content: center; /* Center the content */
  text-align: center;
  margin: 0;
  width: 80%;
  padding-top: 15px;
  font-size: 30px;
  font-weight: 400;
  color: #28282c;
  font-family: "ProximaNova-Medium", sans-serif;
}

.loyality__para,
.loyality__table_heading {
  color: #475467;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  width: 80%;
  padding-top: 16px;
}

/* Table Styles */
.table_container {
  width: 80%; /* Full width for responsiveness */
  border-collapse: collapse;
  margin: 10px 0;
}

/* Apply border to rows */
.table_container tr {
  border-bottom: 1px solid #ddd; /* Display border between rows */
}

.table_head {
  padding: 6px;
  background: #6cad84 !important;
  color: white;
  text-align: center;
  font-size: 16px;
}

.table_data,
.table_data_points {
  text-align: center;
  color: #475467;
  padding: 15px;
  font-size: 16px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 42px 0px 30px 0px;
  width: 80%;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  height: 2px; /* Line thickness */
  background: linear-gradient(
    to right,
    rgba(57, 104, 235, 0) 0%,
    #0052cc 50%,
    rgba(57, 104, 235, 0) 100%
  );
}

.divider::before {
  margin-right: 10px;
}

.divider::after {
  margin-left: 10px;
}

.diamond {
  width: 12px;
  height: 12px;
  background-color: #0052cc; /* Green color for the diamond */
  transform: rotate(45deg); /* Rotate the square to look like a diamond */
}
/* Media Queries for Mobile Responsiveness */
@media screen and (max-width: 1200px) {
  .loyality__para,
  .loyality__table_heading {
    width: 80%; /* Reduce width on medium screens */
  }

  .table_container {
    width: 90%; /* Adjust table width on medium screens */
  }
}

@media screen and (max-width: 768px) {
  .container_heading {
    flex-direction: column; /* Stack heading vertically on smaller screens */
    justify-content: flex-start; /* Align heading to the top */
  }

  .container_heading2 {
    flex-direction: column; /* Stack heading vertically on smaller screens */
    justify-content: flex-start; /* Align heading to the top */
  }

  .loyality__para,
  .loyality__table_heading {
    width: 100%; /* Take full width on small screens */
    padding-top: 8px;
    font-size: 1rem; /* Smaller text size on small screens */
  }

  .table_container {
    width: 100%; /* Full width for small screens */
    margin: 10px 0;
  }

  .table_head,
  .table_data,
  .table_data_points {
    padding: 10px; /* Reduce padding for better fit on small screens */
    font-size: 0.875rem; /* Smaller font size on mobile */
  }
}

@media screen and (max-width: 480px) {
  .table_head,
  .table_data,
  .table_data_points {
    padding: 8px; /* Further reduce padding for mobile */
    font-size: 0.75rem; /* Even smaller font size for mobile */
  }

  .container_heading {
    margin: 0 10px; /* Reduce margin on mobile */
  }
  .container_heading2 {
    margin: 0 10px; /* Reduce margin on mobile */
  }

  .loyality__para,
  .loyality__table_heading {
    font-size: 0.875rem; /* Smaller font size for mobile */
  }
}
