.table_container {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  min-width: 350px;
  height: fit-content;
}

.transactions_container {
  width: 68%;
  padding: 20px;
  margin-top: -363px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.transaction_heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;;
  display: block;
  color: #333;
}

.transaction_table {
  width: 100%;
  border-collapse: collapse;
}

.transaction_table thead {
  background: #f4f4f4;
}

.transaction_table th,
.transaction_table td {
  padding: 8px 8px;
  border-bottom: 1px solid #ddd;
}

.transaction_table th {
  font-weight: bold;
  font-family: "Roboto", sans-serif;;
  color: #555;
  font-size: 16px;

}

.transaction_table .transaction_table tbody tr:nth-child(even) {
  background: #fafafa;
}

.transaction_row:hover {
  background: #f1f1f1;
  transition: background 0.3s ease;
}

.transaction_type {
  font-weight: 600;
  font-size: 14px;
}

.transaction_arrow {
  font-size: 16px;
}

.amount {
  font-weight: bold;
  font-size: 14px;
}

.status {
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
}
.typeOfTransaction_trans{
  font-family: "Roboto", sans-serif;;
}
.date {
  color: #777;
  font-size: 14px;
  font-family: "Roboto", sans-serif;;
}

.status-successful {
  color: #27ae60;
  font-weight: bold;
  text-decoration: none;
  background: #e3fcef;
  padding: 2px 5px;
  font-family: "Roboto", sans-serif;;
  border-radius: 20px;
  font-size: 14px;
}

.status-pending {
  font-weight: bold;
  background: #fff8e1;
  color: #f39c12;
  padding: 2px 5px;
  font-family: "Roboto", sans-serif;;
  border-radius: 20px;
  text-decoration: none;
  font-size: 14px;
}

.status-aborted {
  background: #fdecea;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;;
  text-decoration: none;
  font-size: 14px;
  color: #e74c3c;
}

@media (max-width: 768px) {

  .transactions_container {
    width: 55%;
    margin-top: -355px;
    padding: 10px;
  }
}

.transactions_container li:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

@media (max-width: 480px) {
  .rakeback_container{
margin-left: 5px;
 }
  .table_container {
    margin-bottom: 20px;
    width: 100%;
    padding: 10px;
    overflow-x: auto; 
  }

  .table_container table {
    width: 100%;
    display: block;
  }
 
  .transactions_container {
    width: 350px !important;
    margin-top: 440px;
  }

  .amount {
    font-size: 12px !important;
  }

  .transaction_heading {
    font-size: 15px;
  }

  .transaction_type {
    /* font-size: 13px; */
    display: flex;
  }

  .typeOfTransaction_trans {
    margin: 4px 0px 0 0;
    font-size: 12px;
  }

  .transaction_table th {
    text-align: center;
    font-size: 12px;
    padding: 5px;
  }

  .date {
    font-size: 12px;
  }

  .status-pending,
  .status-successful,
  .status-aborted {
    font-size: 12px;
  }
}