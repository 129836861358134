.DashboardDealCardsContainer {
	border-radius: 0.75rem;
	border: 1px solid #eaecf0;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
		0px 1px 3px 0px rgba(16, 24, 40, 0.1);
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	padding: 1.5rem;
	width: 100%;
}
.cardsContent {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 1rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
}
.deal_head {
	display: flex;
	align-items: center;
	gap: 1.25rem;
	align-self: stretch;
	color: var(--text-primary, #101828);

	font-size: 1.125rem;
	font-weight: 600;
	line-height: 1.75rem; /* 155.556% */
}
@media only screen and (max-width: 1024px) {
	.DashboardDealCardsContainer {
		padding: 1.5rem 1rem;
	}
	.cardsContent {
		grid-template-columns: 1fr;
	}
}
