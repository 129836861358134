.chatContainer {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
}

/* Chat Button */
.chatBtn {
  width: 55px;
  height: 55px;
  background-color: #0052cc;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  perspective: 800px; 
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Flip Wrapper */
.chatBtnInner {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.6s ease;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Flip on active */
.chatBtnActive .chatBtnInner {
  transform: rotateY(180deg);
}

/* Faces */
.chatBtnFront, .chatBtnBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Back face rotates */
.chatBtnBack {
  transform: rotateY(180deg);
}

/* Icon sizes */
.chatBtn .crossIcon {
  width: 40px;
  height: 40px;
  font-size: 35px;
  line-height: 1;
  padding: 0;
}

/* Background changes when active */
.chatBtnActive {
  background-color: #ffbf00;
  color: black;
  
}

/* Popup Card */
.popupCard {
  position: absolute;
  bottom: 70px;
  right: 0;
  width: 280px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 16px;
  animation: fadeInUp 0.4s ease;
  overflow: hidden;
  border: 1px solid #666;
}

/* Contact Options */
.contactOptions a {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  padding: 10px;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contactOptions a:hover {
  background-color: #f1f1f1;
  transform: translateX(4px);
}

.contactOptions img {
  width: 24px;
  height: 24px;
}
.logo {
	width: 9.125rem;
	height: 2.8125rem;
	flex-shrink: 0;
	margin-left: -40px;
}
.logo_fonts{
	font-family: "HP Simplified H", Arial, sans-serif;
}
/* Popup Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive */
@media (max-width: 480px) {
  .popupCard {
    width: 90vw;
  }
}
