.header_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2.1875rem;
	/* margin-bottom: 1rem; */
	
}
.email_input_container {
	display: flex;
	align-items: flex-start;
	gap: 1rem;
	width: 100%;
	max-width: 32rem;
}
.email_input_container .input_content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.375rem;
}
.input_content .input {
	font-size: 1rem;
	display: flex;
	padding: 0.75rem 0.875rem 0.75rem 1.375rem;
	align-items: center;
	gap: 0.5rem;
	align-self: stretch;
	border-radius: 3.3125rem;
	border: 1px solid var(--gray-300, #d0d5dd);
	background: var(--base-white, #fff);

	/* Shadow/xs */
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.input::placeholder {
	color: var(--gray-500, #667085);

	/* Text md/Regular */
	font-family: Inter;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem; /* 150% */
}
@media only screen and (max-width: 768px) {
	.header_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2.1875rem;
	}
	.email_input_container {
		display: flex;
		align-items: flex-start;
		gap: 1rem;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100%;
	}
	.email_input_container .input_content {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.375rem;
	}
	.input_content .input {
		font-size: 1rem;
		display: flex;
		padding: 0.75rem 0.875rem 0.75rem 1.375rem;
		align-items: center;
		gap: 0.5rem;
		align-self: stretch;
		border-radius: 3.3125rem;
		border: 1px solid var(--gray-300, #d0d5dd);
		background: var(--base-white, #fff);

		/* Shadow/xs */
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		width: 100%;
	}
	.input::placeholder {
		color: var(--gray-500, #667085);

		/* Text md/Regular */
		font-family: Inter;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.5rem; /* 150% */
	}
	.searchbtn {
		width: 100%;
	}
	.searchbtn button {
		width: 100%;
	}
}
