.dashboard_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2rem;
	padding: 0 2rem;
}

@media only screen and (max-width: 768px) {
	.dashboard_container {
		padding: 0 1rem;
	}
}
