.PopupWithOpacity {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  min-width: 320px;
}
.opacityDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1040; /* Ensure this is below the modal content */
  opacity: 0.36;
  background: #000;
}
/* Wrapper for form group */
.formGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

/* Label styles */
.putAmountLabel {
  font-size: 16px;
  font-weight: 600;
  color: var(--gray-600, var(--text-secondary, #475467));

  margin-bottom: 8px;
  transition: color 0.3s ease;
}

/* Input field styling */
.amountInput {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  color: #333;
  border-radius: 10px;
  border: 2px solid #ddd;
  outline: none;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

/* Hover and focus effects */
.amountInput:hover,
.amountInput:focus {
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}

/* Placeholder styling */
.amountInput::placeholder {
  color: #aaa;
  opacity: 1;
}

/* Animation effect for input focus */
.amountInput:focus::placeholder {
  opacity: 0.7;
}

.PopupSigninWrapper {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 49rem;
  align-items: center;
  z-index: 1051; /* Ensure this is above the overlay */
}

.PopupSigninWrapperTwo {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 53rem;
  align-items: center;
  height: 30rem;
  z-index: 1051; /* Ensure this is above the overlay */
}

.PopupSigininContainer {
  /* background: url('/src//assets/Frame.gif') no-repeat center center fixed; */
  display: flex;
  height: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  flex: 1 0 0;
  border-radius: 12px;
  background: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-size: auto 600px;
  background-position: center;
}


.bg_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 488px;
  object-fit: cover;
  z-index: -1;
  opacity: 1; 
  border-radius: 14px;
}

.PopupSigininContainerWithdraw {
  display: flex;
  height: 100%;
  padding: 3rem 2.5rem;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  flex: 1 0 0;
  border-radius: 0.75rem;
  background: white;
}

.PopupSigninContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  height: 70vh;
}

/* .PopupSigninText {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.625rem;
	align-self: stretch;
} */

.Head {
  color: black;
  font-family: "Roboto", sans-serif;;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;
  text-align: center;
  line-height: 2.375rem; /* 126.667% */
}
.Subhead {
  color: black;
  font-family: "Roboto", sans-serif;;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: 1.5rem; /* 150% */
}
.Subhead1 {
  color: #002855;
  /* Text md/Regular */
  font-family: "Roboto", sans-serif;;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
.otp_resend span {
  color: #002855;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: "Roboto", sans-serif;;
}
.otp_resend_1 {
  color: #002855;
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;;
}
.alert_message {
  color: red;
  font-family: "Roboto", sans-serif;;
}
.popup_signin_action {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1.5rem;
  align-self: stretch;
  border-radius: 0.75rem;
}

.input_label {
  color: var(--gray-700, #344054);
  font-family: "Roboto", sans-serif;;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: -1rem; /* margin */
  width: 100%;
  text-align: start;
}

.PopupImageContainer img {
  width: 100%;
}
.rakelogo_container {
  position: absolute;
  top: 1.5rem;
  left: 10%;
  transform: translateX(-50%);
}
.otp_button {
  background: #ffbf00;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;;
  padding: 8px 20px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}
.logo {
	margin-left: -50px;
}
.logo_font{
	font-family: "HP Simplified H", Arial, sans-serif;
}

/* .otp_button:hover {
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: white;
  transform: scale(1.05);
} */
.submit_button {
  background: #ffbf00;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  padding: 8px 20px;
  font-family: "Roboto", sans-serif;;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}
/* .submit_button:hover {
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: white;
  transform: scale(1.05);
} */
@media only screen and (max-width: 425px) {
  .PopupWithOpacity {
    bottom: 0;
    align-items: center;
    justify-content: center;
  }

  .opacityDiv {
    width: 100vw;
    height: 100vh;
    bottom: 0;
  }
  .PopupSigininContainer {
    width: 390px;
  }

  .PopupSigininContainer {
    border-top-right-radius: 0.75rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    padding: 1.5rem 1.125rem;

}

  .rakelogo_container {
    margin-left: 30px;
  }
  .PopupImageContainer {
    display: none;
  }

  .Head {
    font-size: 1.75rem;
  }

  .Subhead {
    font-size: 1rem;
  }

  .PopupSigninWrapperTwo {
    grid-template-columns: 1fr;
  }
}
:where([autocomplete="one-time-code"]) {
  --otp-digits: 6;
  --otp-ls: 3ch;
  --otp-gap: 1.25;

  /* private consts */
  --_otp-bgsz: calc(var(--otp-ls) + 1ch);
  --_otp-digit: 0;

  all: unset;
  background: linear-gradient(
      90deg,
      var(--otp-bg, #d8eeff) calc(var(--otp-gap) * var(--otp-ls)),
      transparent 0
    ),
    linear-gradient(
      90deg,
      var(--otp-bg, #d8eeff) calc(var(--otp-gap) * var(--otp-ls)),
      transparent 0
    );
  background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;
  background-repeat: no-repeat, repeat-x;
  background-size: var(--_otp-bgsz) 100%;
  caret-color: var(--otp-cc, #222);
  caret-shape: block;
  clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
  font-family: ui-monospace, monospace;
  font-size: var(--otp-fz, 1.7em);
  inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
  letter-spacing: var(--otp-ls);
  padding-block: var(--otp-pb, 1ch);
  padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap));
}
