.footer_container {
  width: 100%;
  padding-block: 3.75rem;
}
.footer_container > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  background: #0052cc;
}
.footer_container .menu_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}

.menu_container .menu_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.menu_content .logo {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.125rem;
  /* 64.286% */
  letter-spacing: 0.0875rem;
}

.menu_content .menu_items {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 3rem;
}

.menu_items .item {
  color: white;
  text-align: right;

  /* Base (Bold)/L */
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.02rem;
  text-decoration: none;
}

.menu_container .footer_divider {
  display: flex;
  height: 0.0625rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  height: 0.0625rem;
  background: #eaeaea;
}

/*  */
.footer_container .social_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.social_container .copyright {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
}

.copyright .text {
  color: #fff;

  /* Body 2/L */
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6875rem;
  /* 150% */
  letter-spacing: -0.0225rem;
}

.social_container .social_links {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.social_links .link_icon {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 3.5rem;
  background: #fff;
}

.link_icon .icon {
  flex-shrink: 0;
}

@media only screen and (max-width: 768px) {
  .menu_container .menu_content {
    gap: 2rem;
    align-items: flex-start;
    flex-direction: column;
    justify-content: start;
  }

  .menu_content .menu_items {
    flex-direction: column;
    width: 100%;
    justify-content: start;
    gap: 1rem;
  }

  /*  */
  .footer_container .social_container {
    flex-direction: column-reverse;
    justify-content: start;
    align-items: start;
    gap: 1.5rem;
  }
}
