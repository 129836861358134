/* Modal Background Overlay */
.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
  }
  
  /* Modal Box */
  .modalContent {
	background-color: #fff;
	padding: 25px;
	width: 400px;
	border-radius: 12px;
	text-align: center;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	animation: slideDown 0.3s ease-in-out;
  }
  
  /* Animation */
  @keyframes slideDown {
	from {
	  transform: translateY(-30px);
	  opacity: 0;
	}
	to {
	  transform: translateY(0);
	  opacity: 1;
	}
  }
  
  /* Title */
  .title {
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 10px;
	padding: 8px 0;
	border-bottom: 1px solid #ddd;
	color: #333;
	font-family: "Roboto", sans-serif;
  }
  
  /* Transaction ID */
  .transactionId {
	font-size: 12px;
	color: #555;
	margin-bottom: 8px;
	font-family: "Roboto", sans-serif;
  }
  
  /* General Text */
  .modalContent p {
	font-size: 14px;
	color: #333;
	margin: 5px 0;
	font-family: "Roboto", sans-serif;
  }
  
  /* Receipt Container */
  .receiptContainer {
	padding: 15px 0;
  }
  
  /* Transaction Details Row */
  .detailsRow {
	display: flex;
	justify-content: space-between;
	padding: 8px 0;
	font-size: 14px;
	color: #333;
	font-family: "Roboto", sans-serif;
	border-bottom: 1px solid #ddd;
  }
  
  /* Last row should have no border */
  .detailsRow:last-child {
	border-bottom: none;
  }
  
  .status {
	font-weight: bold;
	padding: 4px 10px;
	border-radius: 5px;
	font-size: 13px;
	font-family: "Roboto", sans-serif;
	text-transform: capitalize;
  }
  .status_base_color{
	font-family: "Roboto",sans-serif;

  }
  .Badge {
	display: flex;
	padding: 0.25rem 0.5rem;
	align-items: center;
	border-radius: 1rem;
	mix-blend-mode: multiply;
	height: fit-content;
	line-height: normal;
}
.StatusColumn {
	display: flex;
	align-items: center;
}

  .approved {
	color: #27ae60;
	background: #e3fcef;
	font-weight: bold;
	border-radius: 1rem;
	font-family: "Roboto", sans-serif;
	padding: 0.25rem 0.5rem;
  }
  
  .pending {
	color: #f39c12;
	font-weight: bold;
	background: #fff8e1;
	font-family: "Roboto", sans-serif;
	border-radius: 1rem;
	padding: 0.25rem 0.5rem;
  }
  
  .rejected {
	color: #e74c3c;
	font-weight: bold;
	background: #fdecea;
	border-radius: 1rem;
	font-family: "Roboto", sans-serif;
	padding: 0.25rem 0.5rem;
  }
  
  /* Amount Section */
  .amountContainer {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	font-size: 16px;
	font-weight: bold;
	margin-top: 15px;
	background: #f8f9fa;
	font-family: "Roboto", sans-serif;
	border-radius: 5px;
  }
  
  /* Amount Text */
  .amount {
	color: black;
	font-family: "Roboto", sans-serif;
  }
  
  /* Close Button */
  .closeButton {
	background: #0052cc;
	color: white;
	border: none;
	padding: 12px;
	width: 100%;
	border-radius: 5px;
	font-size: 16px;
	font-family: "Roboto", sans-serif;
	cursor: pointer;
	margin-top: 15px;
	font-weight: bold;
	transition: background-color 0.3s ease;
  }
  
  .closeButton:hover {
	background: #ffbf00;
	color: black;
  }
  .eyeButton{
	background: none;
  }
  /* Responsive Design */
  @media only screen and (max-width: 450px) {
	.modalContent {
	  width: 90%;
	  padding: 20px;
	}
  
	.detailsRow {
	  font-size: 13px;
	  padding: 6px 0;
	}
  
	.amountContainer {
	  font-size: 14px;
	  padding: 8px;
	}
  
	.closeButton {
	  font-size: 14px;
	  padding: 10px;
	}
  }
  