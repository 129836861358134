.deals_container {
	display: flex;
	padding-block: 4rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.625rem;
	border-bottom: 0.0625rem solid #eaeaea;
	margin-bottom: 4rem;
}
.deals_container .content {
	display: flex;
	flex-direction: column;
	gap: 4.5rem;
	width: 100%;
}
.content .head_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.875rem;
}
.head_container .head {
	color: black;
	font-family: Futura;
	font-size: 3rem;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: -0.02rem;
	margin: 0;
}
.head_container .subhead {
	color: #5c6077;
	text-align: center;
	font-family: "Roboto", sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 2.25rem; /* 163.636% */
	letter-spacing: -0.0275rem;
}
.swiper {
	padding-inline: 16px;
}
.swiper > div {
	align-items: stretch;
	padding-block: 1rem;
}
.swiper > div > div {
	height: auto;
}

@media only screen and (max-width: 768px) {
	.title_container .title {
		max-width: none;
	}
	.head_container .head {
		font-size: 1.375rem;
	}
	.head_container .subhead {
		font-size: 1rem;
	}
}
@media only screen and (max-width: 480px){
	.deals_container .content {
gap: 0;
	}
	.head_container .head{
		margin-top: -40px;
	}
	.head_container .subhead{
		margin-top: -10px;
line-height: 1.5rem;
	}
	}