:root {
  --primary-color: #ff6f61;
  --secondary-color: #37474f;
  --accent-color: #00bcd4;
  --background-color: #fefefe;
  --card-bg: #ffffff;
  --text-color: #212121;
  --text-muted: #757575;
  --border-radius: 12px;
  --shadow-light: 0 4px 10px rgba(0, 0, 0, 0.1);
  --shadow-heavy: 0 8px 20px rgba(0, 0, 0, 0.2);
  --shadow-premium: 0 4px 8px rgba(0, 0, 0, 0.06), 0 8px 16px rgba(0, 0, 0, 0.08);
  --transition: 0.3s ease-in-out;
}

.latest_news {
  font-family: "Poppins", sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0 20px 20px;
  line-height: 1.6;
  overflow-x: hidden;
}

.header_container {
  color: var(--black-800, #212121);
  text-align: center;
  font-family: futura;
  font-size: 3rem;
  font-weight: 700;
  margin: 2rem 0;
  letter-spacing: -0.0625rem;
}

.tabs_container {
  margin-top: 20px;
}

.tabList {
  display: flex;
  gap: 2rem;
  list-style: none;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: var(--text-color);
  margin: 0;
  cursor: pointer;
  padding: 0;
}

.tabList > li {
  padding: 0.5rem 1rem;
  border-radius: 10rem;
}

.tabList .active, .tabList .tab:hover {
  background-color: #0052cc;
  color: var(--background-color);
}

.news_section {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 2rem;
}

.content_layout {
  display: flex;
  width: 100%;
}

.news_grid {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  height: fit-content;
}
.card_link {
  text-decoration: none;
  color: inherit;
  display: flex;
  /* align-items: center */
  gap: 8px; /* Minimal spacing */
  transition: background-color 0.2s ease, transform 0.15s ease;
  border-radius: 4px;
}

.card_link:hover {
  background-color: rgba(0, 82, 204, 0.07); /* Very subtle hover effect */
  transform: scale(1.01); /* Tiny lift effect */
}

.post_image {
  width: 45px; /* Slightly smaller */
  height: 45px;
  border-radius: 4px;
  transition: transform 0.2s ease;
}

.card_link:hover .post_image {
  transform: scale(1.05); /* Tiny zoom */
}

.post_content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.post_item {
  display: flex;
  align-items: center;
  padding: 6px 0; /* Minimal padding */
  border-bottom: 1px solid #ddd;
}


.news_card {
  position: relative;
  overflow: visible;
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  height: auto;
  background: transparent;
  border-radius: var(--border-radius);
}

/* Image Wrapper */
.image_wrapper {
  overflow: hidden;
  border-radius: var(--border-radius);
  height: 220px;
  width: 100%;
  position: relative;
  z-index: 1;
}

.news_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease, filter 0.3s ease;
  filter: grayscale(20%);
}

/* Hover Effects */
.news_card:hover .news_image {
  transform: scale(1.1);
  filter: grayscale(0%) brightness(0.9);
}

/* .news_card:hover {
  transform: translateY(-4px);
} */

/* Card Content Overlapping */
.cardContent {
  position: relative;
  background: var(--card-bg);
  margin: -40px 15px 15px;
  padding: 16px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-premium);
  z-index: 2;
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Optional hover lift */
.news_card:hover .cardContent {
  box-shadow: var(--shadow-heavy);
}

.cardMetaTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: var(--text-muted);
  margin-bottom: 10px;
}

.type {
  background: var(--accent-color);
  color: var(--background-color);
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.date {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: var(--text-muted);
}

.heading_card {
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: var(--gray-900, #101828);
  margin-bottom: 10px;
}

.news_description {
  font-size: 13px;
  color: var(--text-muted);
  margin-bottom: 15px;
  line-height: 1.8;
  font-family: "Roboto",sans-serif;
  flex-grow: 1;
}

.cardMeta {
  font-size: 14px;
  color: gray;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: auto;
}
.rightSidebar {
	flex: 1;
	min-width: 270px;
  margin-left: 25px;

}
.writer {
  color: var(--gray-900, #101828);
  font-size: 13px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

/* Important Posts */
.important_post {
margin-top: 20px;
  width: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  backdrop-filter: blur(10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  max-width: 340px;
  padding-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.important_post_heading {
  font-size: 18px;
  color: #333;
  font-weight: 600;
  margin-bottom: 15px;
}

.post_list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.post_item {
  font-size: 14px;
  color: #1f2937;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.post_image {
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 5px;
}

/* Responsive CSS */
@media (max-width: 1024px) {
  .news_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .news_section {
      display: flex;
      flex-direction: column;
  }

  .content_layout {
      display: flex;
      flex-direction: column;
  }

  .news_grid {
      width: 100%;
      grid-template-columns: 1fr;
  }

  .rightSidebar {
      width: 100%;
      margin-left: 0;
      margin-top: 20px; 
      order: 2; 
  }
}

@media (max-width: 480px) {
  .important_post {
margin-left: 10px;
margin-bottom: 10px;
  }

  .content_layout {
    justify-content: center;
    margin-bottom: 20px;
  }

  .para {
    display: none;
  }

  .news_grid {
    grid-template-columns: repeat( 1fr);
    gap: 10px;
    justify-content: center;
    width: 90%;
    margin-top: -10px;
  }

  .news_image {
    height: auto;
  }

  .tabList {
    flex-wrap:wrap;
    display: flex;
    justify-content: center;
    gap: 5px; 
    font-size: 14px;
}


  .tabList .active {
    font-size: 14px;
  }

  .heading_card {
    font-size: 14px;
  }

  .cardMeta {
    margin-top: 10px;
  }
.news_grid{
 margin-left: 20px;
}
/* .news_grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; 
  margin-left: 20px;
} */
.header_container{
  font-size: 24px;
}
  .writer, .date {
    font-size: 12px;
  }
  .rightSidebar{
    margin-left: -5px;
  }
}
