.transactions_header{
	height: 200px;
	background: #0052cc;
	border-radius: 12px;
	background-repeat: no-repeat;
	background-size: 1150px;
	background-position-y:-55px;
	background-position-x: -35px ;

}
/* TabContent */
.TabContent {
	align-self: stretch;
	border-bottom: 0.0625rem #eaecf0 solid;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.5rem;
}
/* TabFilters */
.TabText{
	font-family: "Roboto", sans-serif;
	color: #333;
	font-weight: bold;
	font-size: 14px;
}
.TabFilters {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 1rem;
}

/* TabButton */
.TabButton {
	padding: 0.25rem 0.0625rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	cursor: pointer;
}
@media only screen and (max-width: 768px) {
	.TabContent {
		width: 100%;
	}
}
