/* your-styles.module.css */
.HomeHeader {
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 1.5rem;
	/* 24px / 16px */
	display: inline-flex;
}

.HeaderContainer {
	align-self: stretch;
	/* 69px / 16px */
	padding-left: 2rem;
	/* 32px / 16px */
	padding-right: 2rem;
	/* 32px / 16px */
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 1.5rem;
	/* 24px / 16px */
	display: flex;
}
.header_text_gap{
	margin-left: 12px;
}
.HeaderContent {
	align-self: stretch;

	/* 69px / 16px */
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 1.25rem;
	/* 20px / 16px */
	display: flex;
}

.HeaderContentWrapper {
	align-self: stretch;
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;
	/* 16px / 16px */
	display: inline-flex;
}

.HeaderTexts {
	flex: 1 1 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.25rem;
	/* 4px / 16px */
	display: inline-flex;
}

.HeaderHead {
	align-self: stretch;
	color: #101828;
	font-size: 2rem;
	/* 24px / 16px */
	font-family: "Inter";
	font-weight: 600;
	line-height: 2.375rem;
	/* 38px / 16px */
	word-wrap: break-word;
}

.HeaderActions {
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;
	/* 16px / 16px */
	display: flex;
}

.HeaderNavBtn {
	width: 4rem;
	height: 4rem;
	border-radius: 50%; 
	overflow: hidden; 
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: 2px solid #0052cc; 
  }
   

.HeaderBtnIcon {
	width: 1.25rem;
	/* 20px / 16px */
	height: 1.25rem;
	/* 20px / 16px */
	position: relative;
}

.HeaderBtnIcon svg {
	width: 1.042rem;
	/* 16.67px / 16px */
	height: 1.021rem;
	/* 16.67px / 16px */
}

.HeaderBtnText {
	color: #475467;
	font-size: 1.125rem;
	/* 18px / 16px */
	font-family: "Inter";
	font-weight: 500;
	line-height: 1.75rem;
	/* 28px / 16px */
	word-wrap: break-word;
}

.HeaderNavBtn2 img {
	width: 1.25rem;
	/* 20px / 16px */
	height: 1.25rem;
	/* 20px / 16px */
	width: 2rem;
	height: 2rem;
	position: relative;
}

.HeaderDivider {
	align-self: stretch;
	height: 0.0625rem;
	/* 1px / 16px */
	background: #eaecf0;
}

.MenuAndLogo {
	display: none;
}
.profileModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Overlay */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000; /* Ensure it's above other content */
  }
  
  .modalContent {
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	width: 600px;
	height: 500px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	z-index: 1010; /* Ensure modal content is on top */
  }
  

.modalHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
	margin-bottom: 10px;
	font-size: 24px;
	font-weight: bold;
}

.closeModalBtn {
	background: none;
	border: none;
	font-size: 20px;
	cursor: pointer;
}

.modalBody {
	padding: 10px 0;
}

.modalBody button {
	background-color: #3968EB;
	color: white;
	padding: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

@media only screen and (max-width: 1024px) {
	/* mobile */
	.HeaderContainer {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.HeaderTexts {
		display: none;
	}

	.MenuAndLogo {
		display: flex;
		align-items: center;
		gap: 0.875rem;
		flex: 1;
	}

	.Menu {
		display: flex;
		padding: 0.5rem;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		border-radius: 0.5rem;
		background: var(--base-white, #fff);
	}

	.Menu svg {
		width: 1.5rem;
		height: 1.5rem;
	}
	.logo {
		width: 9.125rem;
		height: 2.8125rem;
		flex-shrink: 0;
	}
	
	.logo_fonts{
		font-family: "HP Simplified H", Arial, sans-serif;
	}
	.Logo svg {
		width: 6.0625rem;
		height: 2.75rem;
	}

	.HeaderActions {
		justify-content: center;
		transform: translateY(15%);
		
	}
}
@media only screen and (max-width: 1024px) {
.HeaderNavBtn{
width: 2.5rem;
height: 2.5rem;
margin-top: -8px;
}
}