.getstarted_container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.getstarted_content {
  width: 100%;
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(440px, 1fr));
  /* border-radius: 1.36388rem; */
  background: linear-gradient(180deg, white, #d8eeff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.getstarted_content .image_container {
  background: linear-gradient(180deg, white, #d8eeff);

  border-bottom-left-radius: 1.36388rem;
  border-top-left-radius: 1.36388rem;
  position: relative;
  width: 100%;
}
.image_container_bg {
  position: absolute;
  top: 0;
  right: 24px;
}

.image_container .image {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.getstarted_content .howto_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.getstarted_content .howto_container > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  padding: 2.5rem;
}
.howto_container .howto_q {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
}
.howto_q .head {
  color: var(--black-800, #212121);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Futura;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 3.15rem */
  letter-spacing: -0.02275rem;
}
.howto_q .subhead {
  color: var(--Text-Blue, #3e3e59);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.93213rem; /* 171.744% */
}
.howto_container .faq_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.25rem;
}
.faq_container .step_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
}
.step_container .item {
  height: auto;
  align-self: stretch;
}
.item .divider {
  display: flex;
  width: 100%;
  padding-right: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.divider span {
  width: 100%;
  height: 0.05681rem;
  flex-shrink: 0;
  background: #d9d9d9;
}
.item .icon_minus {
  display: flex;
  width: 1.59113rem;
  height: 1.59113rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  display: none;
}
.item .text span {
  color: #0052cc;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.93213rem; /* 181.846% */
}
.item .text {
  color: #232334;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.93213rem;
}
.step_container .text {
  align-self: stretch;
  color: #3e3e59;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4775rem; /* 168.857% */
}
.step_container .header {
  align-self: stretch;
  color: #3e3e59;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4775rem; /* 168.857% */
}
.step_container .header span {
  color: #0052cc;
  font-family: "Roboto", sans-serif;
}
.swiper {
  max-width: 480px;
}

@media only screen and (max-width: 768px) {
  .getstarted_content {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    /* gap: 1.5rem; */
  }
  .getstarted_content .image_container {
    width: 100%;
    height: 20rem;
    flex-shrink: 0;
  }
  .image_container .image {
    height: 100%;
    border-bottom-left-radius: 0;
  }
  .image img {
    border-bottom-left-radius: 0;
  }
  .getstarted_content .howto_container {
    align-items: flex-start;
    background: linear-gradient(180deg, white, #d8eeff);

    /* Shadow/xl */
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  }
  .getstarted_content .howto_container > div {
    gap: 1.5rem;
    padding: 1.5rem 1rem;
  }

  .howto_q .head {
    font-size: 1.375rem;
  }
  .howto_q .subhead {
    font-size: 18px;
  }
  .howto_container .faq_container {
    width: 100%;
    gap: 1.5rem;
  }

  .item .text span {
    font-size: 16px;
  }
  .item .text {
    font-size: 16px;
  }

  .getstarted_content .image_container {
    border-radius: 0;
    border-top-left-radius: 1.36388rem;
    border-top-right-radius: 1.36388rem;
  }

  .image_container .image img {
    width: 240px;
    height: auto;
  }
}
@media only screen and (max-width: 480px) {
  .getstarted_content {
    display: flex;
    flex-direction: column;
  padding-bottom: 20px;
  }
  .getstarted_container .howto_container{
   box-shadow: none;
  background: linear-gradient(180deg, white, #d8eeff);

  }
}

  