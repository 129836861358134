.ProgressbarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.ProgressContent {
  display: flex;
  padding-right: 0px;
  align-items: center;
  position: relative;
}
.illustration3_design {
  width: 1070px;
  height: 240px;
  background: #dde3ef;
  border-radius: 0.5rem;
  margin-bottom: 30px;
}
.kyc_status_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-position: calc(100% - 150px) center;
  background-repeat: no-repeat; 
  background-size: 200px;
  width: 100%;
  height: 200px;
  border-radius: 12px;
  padding: 20px 30px;
  position: relative;
  color: white;
  overflow: hidden;
  background-color: #0052cc;
  margin-bottom: 3rem;
}
.heading{
  font-size: 30px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;;
}
.sub_heading{
  font-size: 20px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;;
}
.StepBase {
  display: flex;
  width: 20rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.StepBase1,
.StepBase2 {
  position: relative;
}
.StepBase1::before,
.StepBase2::before {
  content: "";
  position: absolute;
  top: 16px;
  left: 50%;
  width: 100%;
  height: 0.25rem;
  background: #f2f4f7;
  z-index: -1;
}
.StepBase1.StepBaseCompleted::before,
.StepBase2.StepBaseCompleted::before {
  background: #0052cc;
}

.StepIcon {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}

.StepBase .Icon {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: #f2f4f7;
}

.StepBaseCompleted .Icon,
.StepBaseCurrent .Icon {
  background: #0052cc;
}

.StepBase .Icon svg {
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
}
.StepContent {
  display: flex;
  padding-top: 4px;
  flex-direction: column;
  align-items: center;
  gap: 0.125rem;
  align-self: stretch;
  text-align: center;
}
.StepContent .Head {
  color: var(--gray-700, #344054);

  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
.StepContent .Subhead {
  color: var(--gray-600, var(--text-secondary, #475467));

  font-family: Be Vietnam;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}
@media only screen and (max-width: 1024px) {
  .ProgressbarContainer {
    align-items: start;
  }
  .ProgressContent {
    flex-direction: column;
    gap: 1rem;
  }
  .StepBase {
    flex-direction: row;
    width: 100%;
    max-width: 20rem;
    align-items: flex-start;
  }
  .StepContent {
    justify-content: start;
    align-items: start;
    text-align: start;
  }
  .StepBase1::before,
  .StepBase2::before {
    top: 32px;
    left: 14px;
    width: 0.25rem;
    height: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .kyc_status_header {
    background-position-x: 190px;
    background-size: 160px;
  }

  .heading {
    font-size: 16px;
  }

  .sub_heading {
    font-size: 13px;
  }
}