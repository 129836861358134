.deals_card {
	position: relative;
	height: 100%;
	text-decoration: none;
}
.deals_card > div {
	display: flex;
	flex-direction: column;

	height: 100%;

	box-shadow: 0px 4px 6px -2px #10182808;
	box-shadow: 0px 12px 16px -4px #10182814;
}
.deals_card_inner {
	height: 100%;
}
.deals_card:hover > div {
	background: #f6fbff;
}
.deals_card .card_content {
	display: flex;
	flex-direction: column;
	padding: 1.875rem 1.75rem 1.5rem 1.75rem;
	align-items: flex-start;
	gap: 0.625rem;
	border-radius: 0.625rem;
	height: 100%;
	justify-content: space-between;

	/* margin-top: -32px; */
}
.card_text_content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.75rem;
}
.title {
	color: #070a1e;
	font-family: "Roboto", sans-serif;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 160%; /* 2.4rem */
	max-width: 200px;
}

.description {
	color: var(--light-mode-colors-primary-grey-light-mode, #5c6077);
	font-family: "Roboto", sans-serif;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 2.025rem */
}
.card_content .learn_more_container {
	display: flex;
	align-items: center;
	gap: 1.25rem;

	color: #5c6077;
	font-family: "Roboto", sans-serif;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 2.025rem */
	text-decoration: none;
}

.deals_card .image {
	margin-left: auto;
	width: 72px;
	max-height: 72px;
	position: absolute;
	top: 0;
	right: 0;
}
