/* Need to delete following two classes */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow: hidden;
}
.homepage_container {
  overflow: hidden;
}

.coming_soon {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.contact_button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.contact {
  background: #ffbf00;
  color: black;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  padding: 12px 40px;
  border-radius: 6px;
  font-size: 24px;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

/* .contact:hover {
    background: #e6a800; 
} */

@media only screen and (max-width: 480px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    overflow: hidden;
  }

  .coming_soon_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100dvh;
  }

  .coming_soon_mobile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .contact_button {
    position: absolute;
    top: 70vh;
    left: 50%;
    transform: translateX(-50%);
  }

  .contact {
    padding: 8px 16px;
    font-size: 18px;
  }
}
