@import url("https://fonts.cdnfonts.com/css/futura");
@import url("https://fonts.cdnfonts.com/css/Karla");
@import url("https://fonts.cdnfonts.com/css/futura-md-bt");
@import url("https://db.onlinewebfonts.com/c/e55e9079ee863276569c8a68d776ef04?family=Futura+Md+Medium+BT");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
@import url("https://fonts.cdnfonts.com/css/be-vietnam");
@import url("https://fonts.cdnfonts.com/css/poppins");

*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
}

a {
	text-decoration: none;
}
p {
	margin: 0;
}

button,
a {
	cursor: pointer;
}

.flex_center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.body-no-scroll {
	overflow: hidden;
}

.container_max {
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	padding-inline: 1rem;
}
