.PokerCardsContainer {
  width: 100%;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
}

.TabContent {
  align-self: stretch;
  border-bottom: 0.0625rem #eaecf0 solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}

.TabFilters {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

.TabButton {
  padding: 0.25rem 0.0625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.TabText {
  color: #667085;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;;
  font-weight: 600;
  line-height: 1.25rem;
  word-wrap: break-word;
  background-color: transparent;
  border: none;
  padding: 0;
}

.CardsContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;
}

.CardsRow {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.CardItem {
  flex-basis: calc(33.333% - 1rem);
  margin-bottom: 1.5rem;
}

.scrollable {
  overflow-y: auto;
  max-height: 400px;
}

@media (max-width: 768px) {
  .TabFilters {
    gap: 0.75rem;
  }

  .CardsRow {
    flex-direction: row;
  }
  .CardItem {
    /* flex-basis: 100%; */
  }
}

@media (max-width: 480px) {
  .TabFilters {
    gap: 0.75rem;
  }

  .CardsRow {
    flex-direction: row;
    gap: 0;
  }

  .CardItem {
    flex-basis: 100%;
  }
}