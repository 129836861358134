.navbtn_container:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.navbtn_container .text {
	
}

.navbtn_container .icon {
	height: fit-content;
}

.icon svg {
	/* width: 100%;
  height: 100%; */
}
.navbtn_container {
	transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
	color: white; 
  }
  
  .navbtn_container:hover {
	background-color: #ffbf00; 
	color: black; 
  }
  
  

@media only screen and (max-width: 768px) {
	.navbtn_container {
		padding: 0.5rem 1rem;

		/* padding: 8px 20px 8px 20px; */
		gap: 8px;
	}
	.navbtn_container .text {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}
}
