.NotificationsContainer {
  position: absolute;
  top: 5rem;
  right: 2rem;
  display: flex;
  width: 27.5625rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--base-white, #fff);

  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.NotificationsHeader {
  display: flex;
  padding: 0.75rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.NotificationsHeaderContent {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.75rem;
  align-self: stretch;
}

.HeaderContentWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 0 0;
}

.HeaderText {
  color: var(--light-theme-text-text-primary, var(--text-primary, #101828));

  /* Text lg/Medium */
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem; /* 155.556% */
}

.MarkRead {
  color: #0052cc;

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
}

.NotificationsContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: var(--base-white, #fff);
}

.ItemLists {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
}

.ItemContentContainer {
  display: flex;
  padding: 0.125rem 0.375rem;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--light-theme-text-light-gray, #f2f4f7);
  background: #fff;
}

.ItemContentWrap {
  display: flex;
  padding: 0.5625rem 0.625rem;
  align-items: center;
  gap: 0.75rem;
  flex: 1 0 0;
  border-radius: 0.375rem;
}

.IconAndText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
}

.FeaturedIcon {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.375rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--blue-100, #d1e9ff);
}

.FeaturedIcon svg {
  width: 0.75rem;
  height: 0.75rem;
  flex-shrink: 0;
}

.NotificationItemText {
  color: #344054;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
.NotificationItemText span {
  color: var(--gray-700, #344054);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 142.857% */
}
.DotIcon svg {
  width: 0.75rem;
  height: 0.75rem;
}
.backArrow {
  display: none;
}
@media only screen and (max-width: 768px) {
  .NotificationsContainer {
    width: 100%;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
  }
  .HeaderContentWrap {
    justify-content: start;
  }
  .FeaturedIcon {
    width: 3rem;
    height: 3rem;
  }

  .FeaturedIcon svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  .backArrow {
    display: block;
    padding-right: 1rem;
  }
  .MarkRead {
    display: none;
  }
}
