.text_input_container {
	display: flex;
	padding: 0.625rem 0.875rem;
	align-items: center;
	gap: 0.5rem;
	align-self: stretch;
	border-radius: 2rem;
	border: 2px solid #ccc;
	background: var(--base-white, #fff);

	/* Shadow/xs */transition: border 0.3s;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	
}

.text_input_wrapper svg {
	width: 1.25rem;
	height: 1.25rem;
	position: absolute;
	top: 50%;
	right: 1rem;
	transform: translateY(-50%);
}

.text_input_wrapper input,
.text_input_wrapper input::placeholder {
	color: var(--gray-800, #667085);

	/* Text md/Regular */
	font-family: Be Vietnam;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	/* 150% */
}

.text_input_container::placeholder {
	/* opacity: 0.4; */
}
