.NewPokerContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.NewPokerContent {
  width: 100%;
  padding: 2.25rem 1.5rem;
  background: #0052cc;
  box-shadow: 0px 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
  border-radius: 0.75rem;
  border: 0.0625rem solid #eaecf0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.25rem;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 580px;
}

.NewPokerHead {
  color: white;
  font-size: 22px;
  font-family: "Roboto", sans-serif;;
  font-weight: 600;
  line-height: 2.375rem;
}
.submit {
  background: #ffbf00;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 20px;
  font-family: "Roboto", sans-serif;;
  border-radius: 30px;
  margin-top: 0px;
}
.submit:hover {
  color: black;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);

}
.NewPokerCreate {
  display: flex;
  /* align-self: stretch; */
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.25rem;
  height: 3rem;
}
.pokeridsheadericon {
  width: 150px;
  margin-left: 50px;
}
.NewPokerSelect,
.SelectContent,
.NewPokerAccId {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
text-decoration: none;
}
.NewPokerSelect,
.NewPokerAccId {
  min-width: 20rem;
  gap: 0.5rem;
}
.select_poker_site{
  color: black;
  font-family: "Roboto", sans-serif;;
}
::placeholder {
  color: var(--gray-500, #667085);
  font-family: "Roboto", sans-serif;;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.NewPokerSelect input {
  font-size: 1rem;
}

.SelectContent {
  gap: 0.375rem;
}

.SelectWrapper,
.SelectBtn,
.Input {
  background: white;
  box-shadow: 0px 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
  border-radius: 2rem;
  border: 0.0625rem solid #d0d5dd;
}

.SelectWrapper {
  padding: 0.625rem 0.875rem;
}

.SelectMenu {
  position: absolute;
  top: 3.5rem;
  left: 0;
  right: 0;
  z-index: 1;
  background: white;
  box-shadow: 0px 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
  border-radius: 0.75rem;
  border: 0.0625rem solid #eaecf0;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
}

.SelectBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0.875rem;

  width: 100%;
}
.SelectedValue {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.SelectMenu > button {
  padding: 0.7rem 1rem;
  color: black;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-family: "Roboto", sans-serif;;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  background-color: white;
  border: 0;
}
.SelectMenu > button:hover {
  background-color: #f5f7fa;
}
.SelectMenu .active {
  background-color: #e9eaeb;
  color: black;
}

.InputWithLabel,
.Input {
  width: 100%;
  display: flex;
  align-self: stretch;
  justify-content: flex-start;
}

.InputWithLabel {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
}

.Input {
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 0.875rem;
}

.Content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.Text {
  color: #667085;
  font-size: 1rem;
  font-family: "Be Vietnam";
  font-weight: 400;
  line-height: 1.5rem;
  word-wrap: break-word;
}

@media only screen and (max-width: 1024px) {
  .NewPokerContainer {
    width: 100%;
  }

  .NewPokerCreate {
    flex-direction: column;
  }

  .NewPokerSelect,
  .NewPokerAccId,
  .navbtn_container,
  .submitbtn_container {
    width: 100%;
  }
  .NewPokerSelect,
  .NewPokerAccId {
    min-width: 100%;
    gap: 0.5rem;
  }
  .NewPokerContent {
    padding: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .NewPokerContent {
    flex-direction: column;
    align-items: center;
    background: #0052cc;
    padding-bottom: 120px;
    border: none;
    background-size: 814px;
    background-repeat: no-repeat;
 
  }
  .submit_button {
    margin-top: 55px;
  }
.submit{
  font-size: 16px;
}
  .NewPokerCreate {
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
  }

  .NewPokerSelect,
  .NewPokerAccId {
    min-width: 100%;
  }

  .pokeridsheadericon {
    width: 100px;
    margin-left: 220px;
    margin-top: -55px;
  }

  .SelectMenu {
    top: 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .NewPokerContent {
    flex-direction: column;
    align-items: center;
    background: #0052cc;
    padding-bottom: 88px;
    border: none;
    background-size: 814px;
    background-repeat: no-repeat;
    background-position-x: -299px;
 
  }
  .submit_button {
    margin-top: 24px;
  }
.submit{
  padding: 6px 16px;
  font-size: 14px;
}

  .NewPokerCreate {
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
  }

  .NewPokerSelect,
  .NewPokerAccId {
    min-width: 100%;
  }

  .pokeridsheadericon {
    width: 100px;
    margin-left: 220px;
    margin-top: -55px;
  }

  .SelectMenu {
    top: 3rem;
  }
  .SelectBtn{
    padding: 2px 10px;
  }
  .SelectWrapper{
    padding: 4.82px 10px;

  }
}

