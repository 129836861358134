.about__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-block: 4.5rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.0625rem solid #eaeaea;
  gap: 2rem;
  background: linear-gradient(180deg, white, #d8eeff);
  margin-top: -40px;

  /* border-radius: 1.36388rem; */
}
.about__container .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  max-width: 600px;
  margin-inline: auto;
}
.content .tag {
  display: flex;
  padding: 0.25rem 1rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 6.25rem;
  border: 1px solid #0052cc;
  width: fit-content;
  
}
.tag .text {
  color: #0052cc;
  text-align: center;

  font-feature-settings: "clig" off, "liga" off;

  /* Text/14px/Semibold */
  font-family: "Roboto", sans-serif;

  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  /* line-height: 1.375rem;  */
  /* 157.143% */
}
.content .info_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
}
.info_container .head_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
}
.head_info .head_cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.head_cont .head_text {
  color: #070a1e;
  font-feature-settings: "clig" off, "liga" off;
  font-family:Futura;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 3.9375rem */
  letter-spacing: -0.0375rem;
}
.head_cont .subhead_text {
  color: #3e4959;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem; /* 177.778% */
}
.info_container .list_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.75rem;
  align-self: stretch;
}
.btn_container .btn {
  background: #ffbf00;
  color: black;
  transition: background 0.3s #0052cc, color 0.3s #0052cc;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}

.btn_container .btn:hover {
  background: #0052cc;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);

}

.list_info .item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}
.item .bullet {
  width: 2rem;
  height: 2rem;
}
.item .text {
  flex: 1 0 0;
  color: #3e3e59;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem; /* 200% */
  letter-spacing: -0.0225rem;
}

.about__container .image {
  display: flex;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-inline: auto;
}

@media only screen and (max-width: 768px) {
  .about__container {
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
    padding:16px;
  }

  .head_cont .head_text {
    width: 100%;
    font-size: 1.375rem;
  }
  .head_cont .subhead_text {
    width: 100%;
    font-size: 1rem;
  }

  .item .text {
    font-size: 1rem;
    line-height: 2rem; /* 200% */
    letter-spacing: -0.02rem;
  }
  .info_container .btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
  }
  .about__container .image {
    max-width: 15.625rem;
    /* height: 13.375rem; */
    margin: auto;
  }
}
@media only screen and (max-width: 768px){
  .about__container{
    margin: 0;
  }
.tag .text {
font-size: 16px;
}
.tag{
  margin-bottom: 10px;
}
.list_info{
  margin-top: -30px;
}
.btn_container .btn{
  font-size: 14px;
  padding: 8px 14px;
  margin-top: -30px;
}

}
