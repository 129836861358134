.heading{
    color: #5c6077;
	text-align: center;
  font-family: "Roboto", sans-serif;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 2.25rem; 
	letter-spacing: -0.0275rem;
    margin-bottom: 30px; 
    margin-top: 30px;
}
.slider_container {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden; 
    white-space: nowrap;
  }
  
  .slider {
    display: flex;
    width: max-content;
    animation: scroll 25s linear infinite;
  }
  
  .slider:hover {
    animation-play-state: paused;
  }
  
  .slider a {
    text-decoration: none;
  }
  
  .user_card {
    margin-right: 16px; 
    transition: transform 0.3s ease;
  }
  
  .user_card:hover {
    transform: scale(1.05);
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%); 
    }
  }
  
  .logo_container {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; 
    margin-right: 50px;
  }
  
  .logo {
    width: 100%; 
    height: 100%; 
    object-fit: contain;
  }
  

@media only screen and (max-width: 480px){
  .logo_container {
    width: 60px; 
    height: 60px;
  }
  
  .logo {
    width: 100%;
    height: 100%;
  }
  }