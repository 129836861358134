.bonuses_and_review_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
}
.bonuses_and_review_wrapper .horizontal_tabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
}
.horizontal_tabs .tabs {
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
}
.tabs .tab_button_base {
  display: flex;
  padding: 0.5rem 0.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #101828;
}
.tab_button_base .head {
  /* Text lg/Semibold */
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem; /* 155.556% */
}
.tab_button_base .subhead {
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}
.tabs .focused {
  color: #0052cc;
  border-bottom: 2px solid #0052cc;
}

/*  */
.bonuses_and_review_wrapper .bonus_and_review_container {
  display: flex;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  gap: 1.875rem;
}
.bonus_and_review_container .content_container {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  border-radius: 0.5rem;
  width: 40%;
  background: #f5f8ff;
}
.content_container .heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-900, #28282c);
  font-size: 20px;
  font-family: "ProximaNova-Semibold", sans-serif !important;

  /* Text lg/Medium */
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem; /* 155.556% */
}
.content_container .divider {
  height: 0.0625rem;
  align-self: stretch;
  background: #c5d4fc;
}
.content_container .content_list_ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  align-self: stretch;
}
.content_list_ul .list {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.list svg {
  width: 0.3125rem;
  height: 0.3125rem;
}
.list .text {
  color: #475467;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}
.content_link {
  color: #475467;
}
/*  */
/* .bonus_and_review_container .bonus_and_review_description {
	display: flex;

	flex-direction: column;
	align-items: flex-start;
	gap: 0.75rem;
	align-self: stretch;
} */
.bonus_and_review_container .head {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 133.333% */
}
.bonus_and_review_container .description_text {
  align-self: stretch;
  color: #28282c;
  font-family: "ProximaNovaA-Medium", sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px; /* 142.857% */
}
/*  */
.bonus_and_review_container .freerolls_container {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid #d0d5dd;
  width: 100%;
}
.freerolls_container .head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-900, #101828);

  /* Text lg/Medium */
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem; /* 155.556% */
}
.freerolls_container .divider {
  height: 0.0625rem;
  align-self: stretch;
  background: #eaeaea;
}
.freerolls_container .freeroll_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  align-self: stretch;
}
.freeroll_content .freeroll_amount {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
}
.freeroll_amount .icon {
  width: 1.5rem;
  height: 1.84144rem;
}
.freeroll_amount .amount_and_find {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.amount_and_find .amount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-900, #101828);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}
.amount_and_find .find {
  color: #0052cc;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
}
.freeroll_content .countdown {
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
}
.countdown .date_container {
  /* combining date_type */
  /* width: 3.5625rem;
	height: 3.75rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px dashed #0052cc;
  background: rgba(255, 255, 255, 0);
}
.date_container .time {
  color: #475467;
  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.98106rem; /* 65.404% */
}
.date_container .time span {
  display: block;
  color: #475467;
  font-family: Inter;
  font-size: 0.63069rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
}
/*  */
.freeroll_content .freeroll_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
}
.freeroll_info .info_container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.info_container .icon {
  width: 1.125rem;
  height: 1.125rem;
}
.info_container .text {
  color: #475467;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}
.info_container .text span {
  color: #0d9488;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}
.description_text_line {
  margin: 15px 0px 15px 0px;
  color: #28282c;
  font-weight: 600;
  font-style: normal;
  font-family: "ProximaNova-Bold", sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  line-height: 1.5;
}
.description_text_line_1 {
  color: #475467;
  font-size: 14px;
}
.description_text_line_para {
  color: #475467;
  font-family: "ProximaNova-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 0px 0px 0px;
}
.two_column_layout {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.left_column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.right_column {
  flex: 1;
  display: flex;
  flex-direction: column;
}
/* Parent container for the two columns */
.bonus_and_review_container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

/* Two-Column Layout */
.two_column_layout {
  display: flex;
  gap: 2rem; /* Space between columns */
  width: 100%;
  justify-content: space-between; /* Ensure columns are spaced evenly */
}

/* Left Column */
.left_column {
  flex: 1; /* Equal width with the right column */
  padding: 1rem;
  box-sizing: border-box;
  background: #f9f9f9; /* Optional background color */
}

/* Right Column */
.right_column {
  flex: 1;
  /* Equal width with the left column */
  padding: 1rem;
  box-sizing: border-box;
  background: #f5f8ff;
  /* Optional background color */
}

/* Freerolls Section */
.freerolls_container {
  padding: 1rem;
  background-color: #e7f3ff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
/* Tab Container */
.horizontal_tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

/* Tab Buttons - Make the container scrollable horizontally */
.tabs {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  overflow-x: auto; /* Make the container scrollable horizontally */
  width: 100%;
  padding-bottom: 10px; /* Optional: Padding to avoid scrollbar touching the tabs */
  scrollbar-width: thin; /* For Firefox - makes the scrollbar thinner */
  -ms-overflow-style: none; /* For Internet Explorer */
}

/* Hide default scrollbar in Webkit browsers (Chrome, Safari) */
.tabs::-webkit-scrollbar {
  display: none;
}

/* Tab Button Base */
.tab_button_base {
  cursor: pointer;
  padding: 10px 20px;
  margin: 5px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
  white-space: nowrap; /* Prevent text from breaking into multiple lines */
}

/* Hover Effect */
.tab_button_base:hover {
  background-color: #f8f8f8;
  transform: scale(1.05);
}

/* Active Tab Styling */
.tab_button_base.active {
  background-color: #4caf50;
  color: white;
  font-weight: bold;
  border: 1px solid #4caf50;
}
@media only screen and (max-width: 1024px) {
  .two_column_layout {
    flex-direction: column;
    gap: 1rem;
  }
  
  .left_column, .right_column {
    width: 100%;
  }
  .content_container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .content_container table {
    display: block;
    width: max-content; /* Prevents shrinking */
    max-width: 100%;
  }

  .content_container th,
  .content_container td {
    font-size: 14px;
    padding: 8px;
  }
}

.bottom_center_popup {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 15px 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  max-width: 900px;
  width: calc(100% - 40px);
  gap: 15px;
  animation: fadeInUp 0.5s ease-out;
  flex-wrap: wrap;
}
.fire_banner {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

/* Stable fire flicker */
.fire {
  display: inline-block;
  animation: flicker 1s infinite ease-in-out;
  margin: 0 8px;
}

@keyframes flicker {
  0% { transform: scale(1) rotate(0deg); opacity: 1; }
  25% { transform: scale(1.05) rotate(-3deg); opacity: 0.95; }
  50% { transform: scale(1) rotate(0deg); opacity: 1; }
  75% { transform: scale(1.05) rotate(3deg); opacity: 0.97; }
  100% { transform: scale(1) rotate(0deg); opacity: 1; }
}

.popup_content {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  flex-wrap: wrap;
}

.popup_logo {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  /* object-fit: cover; */
  flex-shrink: 0;
}

.popup_text {
  flex: 1;
  font-size: 14px;
  line-height: 1.4;
  min-width: 150px;
}

.popup_referral {
  text-align: right;
  margin-right: 10px;
  font-size: 14px;
  min-width: 100px;
}

.popup_referral b {
  color: gold;
}

.copy_btn {
  background: none;
  border: none;
  color: gold;
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
}

.register_btn {
  background: #0052cc;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

.register_btn:hover {
  background: #ffbf00;
  color: black;
}

.close_popup {
  position: absolute;
  top: -5px;
  right: 5px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
}

/* Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate(-50%, 30%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.tooltip {
  position: absolute;
  top: -30px;
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  animation: fadeInOut 1.5s ease-in-out;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}




@media only screen and (max-width: 768px) {
  .bonuses_and_review_wrapper .bonus_and_review_container {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .bottom_center_popup {
    display: none;
  }
  .content_container {
    width: 100%;
    padding: 1rem;
  }

  .bonus_and_review_container .description_text {
    font-size: 20px;
    line-height: 28px;
  }
  
  .horizontal_tabs .tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
  }

  .tab_button_base {
    font-size: 14px;
    padding: 8px 12px;
  }
}

@media only screen and (max-width: 480px) {
  .bonus_and_review_container .description_text {
    font-size: 18px;
    line-height: 26px;
  }
 .bonus_and_review_container .content_container{
  width: 100%;
}
  .tab_button_base {
    font-size: 12px;
    padding: 6px 10px;
  }

  .freerolls_container {
    padding: 0.75rem;
  }

  .tabs {
    gap: 0.5rem;
  }
}
